import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

function SupportAforeAlert({ noButton = false }) {
    return (
        <Alert variant="warning" className="text-center text-md-left">
            <div className="d-flex flex-column flex-md-row align-items-center">
                <div style={{ whiteSpace: "nowrap" }}>
                    <b>Afore update</b>
                </div>
                <div className="flex-grow-1 mx-md-3 mb-2 mb-md-0">
                    Er is een update voor de monitoring van Afore omvormers. Het kan zijn dat u een nieuwe app moet downloaden.
                </div>
                { !noButton && (
                    <Link to="/solarman3/" className="btn btn-warning btn-sm" style={{ whiteSpace: "nowrap" }}>
                        Meer informatie
                    </Link>
                )}
            </div>
        </Alert>
    )
}

export default React.memo(SupportAforeAlert);
