import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

function AutelACWallboxLaadpaal() {
    return (
        <React.Fragment>
            <Helmet
                title="Autel AC Wallbox"
                description="Laadpaal aanschaffen? Ontdek de Autel AC Wallbox-laadpaal bij Smith Solar en stap vandaag nog over op duurzaam opladen. Contacteer ons voor meer info!"
            />

            {/*<LaadpaalSubNavbar/>*/}

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Autel AC Wallbox</h1>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <div className="article-open">
                    <p>
                        Ben je op zoek naar een geavanceerde laadoplossing voor jouw elektrische voertuig? Dan is de Autel AC Wallbox precies wat je
                        nodig hebt. Met zijn innovatieve functies en gebruiksvriendelijk ontwerp maakt deze laadpaal elektrisch rijden praktisch en
                        eenvoudig. Bij Smith Solar ben je aan het juiste adres voor het aanschaffen van de Wallbox-laadpaal. Lees verder en ontdek
                        alles over deze efficiënte laadoplossing.
                    </p>
                </div>
            </div>

            <div className="my-5 py-5 bg-white">
                <div className="container mt-4">
                    <h2 className="mb-4 text-center">Wat zijn de voordelen van een eigen laadpaal?</h2>

                    <div className="row">
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{ fontSize: "1.3rem" }}>Opladen wanneer het jou uitkomt</h3>
                            <p>
                                Met een eigen laadpaal kun jij je elektrische wagen opladen wanneer het jou uitkomt, zonder je woning te moeten
                                verlaten.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{ fontSize: "1.3rem" }}>Besparen op laadkosten</h3>
                            <p>Door thuis op te laden bespaar je op laadkosten. De prijzen bij openbare laadstations liggen namelijk vaak hoger.</p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{ fontSize: "1.3rem" }}>Recht op subsidies</h3>
                            <p>
                                Bij de aankoop van een laadpaal heb je vaak recht op allerlei subsidies, waardoor het een interessante investering is.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={{ fontSize: "1.3rem" }}>Duurzaam en milieuvriendelijk</h3>
                            <p>Een laadpaal is een duurzame en milieuvriendelijke manier om je auto op te laden.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="properties-container">
                    <div className="container container-sm">
                        <h2>Eigenschappen van de Autel Wallbox-laadpaal</h2>
                        <p className="description">
                            De Wallbox van Autel is een innovatieve laadpaal die heel wat te bieden heeft. Denk hierbij aan krachtige prestaties,
                            flexibele oplaadmogelijkheden, intuïtieve bediening, slimme energieregeling en meer. Elektrisch rijden wordt dankzij deze
                            laadpaal nog eenvoudiger en handiger. Hieronder nemen we een diepgaande kijk naar alle eigenschappen van de Autel AC
                            Wallbox.
                        </p>
                    </div>
                    <div className="properties-grid">
                        <div>
                            <h3>Krachtige prestaties</h3>
                            <p>
                                De Autel AC Wallbox is beschikbaar voor zowel 1 fase (tot 7,4 kW) als 3 fase (tot 22 kW) aansluitingen, waardoor de
                                laadpaal geschikt is voor verschillende elektrische voertuigen. Of je nu een compacte stadsauto hebt of een krachtige
                                elektrische SUV, deze laadpaal kan het aan.
                            </p>
                        </div>
                        <div>
                            <h3>Flexibele oplaadmogelijkheden</h3>
                            <p>
                                Met de Autel-laadpaal heb je de keuze tussen een vaste of losse laadkabel, afhankelijk van jouw voorkeur en behoeften.
                                Deze flexibiliteit zorgt ervoor dat je altijd optimaal kunt opladen, ongeacht de omstandigheden.
                            </p>
                        </div>
                        <div>
                            <h3>Intuïtieve bediening</h3>
                            <p>
                                Dankzij het grote 5-inch LCD touchscreen kun je de laadpaal eenvoudig bedienen en de laadstatus in één oogopslag
                                bekijken. Het intuïtieve interface maakt het starten en beheren van laadsessies moeiteloos.
                            </p>
                        </div>
                        <div>
                            <h3>Slimme energieregeling</h3>
                            <p>
                                Met dynamic load balancing via een meter in je groepenkast zorgt de Autel AC Wallbox laadpaal ervoor dat je
                                elektriciteitsnet niet overbelast raakt. Als er in huis teveel energie wordt gebruikt, regelt de laadpaal zich terug,
                                zodat de hoofdzekering er niet uit knalt.
                            </p>
                        </div>
                        <div>
                            <h3>Gemakkelijke start van laadsessies</h3>
                            <p>
                                Start je laadsessies moeiteloos met behulp van een RFID kaart of via de handige Autel Charge app op je smartphone. Zo
                                heb je altijd controle over je laadactiviteiten, waar je ook bent.
                            </p>
                        </div>
                        <div>
                            <h3>Altijd verbonden</h3>
                            <p>
                                Dankzij de optionele 4G verbinding blijft jouw Autel AC Wallbox altijd online, zodat je op elk moment de status kunt
                                controleren en updates kunt ontvangen. Hierdoor ben je verzekerd van een betrouwbare en up-to-date laadervaring.
                            </p>
                        </div>
                        <div>
                            <h3>Compatibiliteit met elke elektrische auto</h3>
                            <p>
                                De Autel-laadpaal is compatibel met elk merk en model elektrische auto. Of je nu met een Tesla, Nissan, BMW of een
                                ander merk rijdt, deze laadpaal kan het aan.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container container-sm">
                <div className="card-rounded">
                    <h2>Neem contact op met ons</h2>
                    <p>
                        Wil je meer informatie over de Autel AC Wallbox of wil je meteen overgaan tot de aankoop van deze laadpaal? Onze experts
                        voorzien je graag van advies op maat en een vrijblijvende offerte.
                    </p>
                    <Link to="/contact/" className="btn btn-primary">
                        Neem contact op
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(AutelACWallboxLaadpaal);
