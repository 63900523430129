import React from "react";
import {
    Link
} from "react-router-dom";

import seInstallerPlusLogo from "../img/solaredge-installer-plus/solaredge-installer-plus-fullwhite.svg";

function Footer() {
    const firstYear = 2014;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <div className="jumbotron jumbotron-fluid footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                            <p className="h4 footer-title">
                                Producten
                            </p>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link
                                        to="/zonnepanelen/"
                                        className="nav-link footer-link"
                                    >
                                        Zonnepanelen
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/thuisbatterij/"
                                        className="nav-link footer-link"
                                    >
                                        Thuisbatterij
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/laadpalen/"
                                        className="nav-link footer-link"
                                    >
                                        Laadpalen
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/ems/"
                                        className="nav-link footer-link"
                                    >
                                        Energie Management Systeem (EMS)
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                            <p className="h4 footer-title">
                                Meer informatie
                            </p>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link
                                        to="/support/"
                                        className="nav-link footer-link"
                                    >
                                        Support
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://referenties.smithsolar.nl/"
                                        className="nav-link footer-link"
                                    >
                                        Referenties
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="/contact/"
                                        className="nav-link footer-link"
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0">
                            <p className="h4 footer-title">
                                Meer van ons
                            </p>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link
                                        to="/"
                                        className="nav-link footer-link"
                                    >
                                        Smith Solar B.V.
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://gridsense.nl/"
                                        className="nav-link footer-link"
                                    >
                                        GridSense
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://werkenbijsmith.nl/"
                                        className="nav-link footer-link"
                                    >
                                        Werken bij Smith
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href="https://smithtuinbouwtechniek.nl/"
                                        className="nav-link footer-link"
                                    >
                                        Smith Tuinbouwtechniek B.V.
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col d-flex flex-column justify-content-center align-items-center">
                            <Link to="/solaredge-installer-plus/">
                                <img
                                    src={seInstallerPlusLogo}
                                    style={{height: "35px"}}
                                    alt="SolarEdge Installer Plus"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jumbotron jumbotron-fluid footer-secondary">
                <div className="container">
                    <div className="d-flex flex-column flex-md-row text-center">
                        <div className="flex-grow-1 text-md-left">
                            Copyright &copy; {yearString} Smith Solar B.V. All rights reserved.
                        </div>
                        <div className="text-md-right mt-1 mt-md-0">
                            A website by{" "}
                            <a
                                href="https://srproductions.nl"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                SR Productions
                            </a>.
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Footer);
