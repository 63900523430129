import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import isValidBSN from "./BSNValidate";

function BSNFormatter({ bsn, hideBSN = false, tooltip = false }) {
    const valid = isValidBSN(bsn);
    if(tooltip) {
        if(valid) {
            return (
                <React.Fragment>
                    { hideBSN ? "" : bsn }
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="iban">BSN geldig</Tooltip>
                    }>
                        <i className="fas fa-check ml-2 text-success"/>
                    </OverlayTrigger>
                </React.Fragment>
            )
        }
        return (
            <span className="text-warning">
                { hideBSN ? "" : bsn }
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="iban">BSN mogelijk niet geldig</Tooltip>
                    }>
                    <i className="fas fa-exclamation-triangle ml-2"/>
                </OverlayTrigger>
            </span>
        )
    } else {
        if(valid) {
            return (
                <span className="text-success">
                    <i className="fas fa-check mr-2 text-success"/>
                    BSN geldig
                </span>
            )
        } else {
            return (
                <span className="text-warning">
                    <i className="fas fa-exclamation-triangle mr-2"/>
                    BSN mogelijk niet geldig
                </span>
            )
        }
    }
}

export default React.memo(BSNFormatter);
