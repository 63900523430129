import React from "react";
import {
    Link
} from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Helmet from "../components/Helmet";
import JumbotronCallToAction from "../components/JumbotronCallToAction";
import CallToAction from "../components/CallToAction";

import gridSenseLogo from "../img/gridsense/gridsense-logo.svg";
import aforeLogo from "../img/brands/afore.svg";
import solarEdgeLogo from "../img/brands/solaredge.svg";
import goodweLogo from "../img/brands/goodwe.svg";

function EMSSupportedBrand({ name, logo, soon }) {
    return (
        <div className="col-6 col-md mb-2 px-1">
            <div className="card-rounded text-center">
                <OverlayTrigger overlay={ (props) => (
                    <Tooltip id={ `tooltip-${ name }` } {...props}>
                        { name }
                    </Tooltip>
                ) }>
                    <div className="ems-brand">
                        <img
                            src={ logo }
                            alt={ name }
                            className="img-fluid"
                        />
                        { soon && (
                            <div className="ems-brand-soon">
                                <b>Binnenkort</b>
                            </div>
                        )}
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    );
}

function EMS() {
    return (
        <React.Fragment>
            <Helmet
                title="Energie Management Systeem"
                description="Slimme sturing voor thuisbatterijen kopen? Smith Solar biedt energie management systemen voor slimme sturing op dynamische energieprijzen. Contacteer ons!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Energie Management Systeem</h1>
                    <p className="lead">
                        Slimme sturing op dynamische energieprijzen
                    </p>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">

                <div className="article-open">
                    <p>
                        De snel evoluerende wereld van hernieuwbare energie opent de deuren naar innovatieve
                        oplossingen. Energie management systemen bieden de uitkomst om thuisbatterijen van zonnepanelen
                        op een slimme manier te sturen. Of je nu thuis bent of een bedrijf runt, een EMS is een slimme
                        en duurzame technologie om je energieverbruik te optimaliseren en optimaal te beheersen. Bij
                        Smith Solar bieden we een energie management systeem om ervoor te zorgen dat je de thuisbatterij
                        slim kunt sturen. Lees verder en ontdek welke voordelen dit systeem met zich meebrengt.
                    </p>
                </div>

                <h2>
                    Thuisbatterij slim sturen met dynamische energieprijzen
                </h2>
                <p>
                    Zon en windenergie worden gebruikt om groene stroom op te wekken. Bij zon gebeurt dit voornamelijk
                    in de middaguren. Op deze momenten is er minder vraag naar stroom. Dit leidt ertoe dat de
                    overschotten aan groene stroom verloren gaan. Het slim inzetten van thuisbatterijen zorgt ervoor dat
                    huishoudens deze groene stroom van het net kunnen opslaan voor later gebruik of aan het net kunnen
                    verkopen tegen het hoogste tarief. Een energie management systeem draagt bij aan de slimme sturing
                    van thuisbatterijen en beheert het energieverbruik op efficiënte wijze.
                </p>

                <h2>
                    Integratie van het energie management systeem
                </h2>
                <p>
                    Het wordt steeds interessanter om je elektriciteitsvraag te spreiden of zo veel mogelijk te
                    verschuiven naar momenten waarop er veel energie ter beschikking is. Een energie management systeem
                    is een technologie die daarbij kan ondersteunen. Dit is een apparaat dat de opgeslagen zonnestroom
                    in je thuisbatterij op een slimme manier inzet. Energiemanagement meet, monitort en beheert het
                    volledige energieverbruik van je woning of bedrijf. Hierbij worden de verschillende energiestromen
                    in kaart gebracht en energiebesparende maatregelen toegepast.
                </p>

                <div
                    className="card-rounded w-100 text-center my-4"
                >
                    <img
                        src={ gridSenseLogo }
                        alt="GridSense"
                        className="img-fluid"
                        style={ { maxHeight: "60px" } }
                    />
                </div>

                <h2>
                    Slim energiebeheer met GridSense
                </h2>
                <p>
                    Bij Smith Solar bieden we het energie management systeem GridSense, waarmee thuisbatterijen slim
                    worden aangestuurd op basis van dynamische energieprijzen. GridSense laadt de thuisbatterij op met
                    eigen zonnestroom tijdens de laagste prijzen van de dag. Hierdoor maak je heel het jaar optimaal
                    gebruik van je thuisbatterij, ook tijdens de wintermaanden. Ons systeem voorziet ondersteuning voor
                    de SolarEdge-omvormer. Met een SolarEdge systeem wordt de opbrengst van elk afzonderlijk zonnepaneel
                    geoptimaliseerd, resulterend in maximale efficiëntie.
                </p>

            </div>

            <div className="container my-4">
                <div className="row justify-content-center" style={{ marginLeft: "-0.25rem", marginRight: "-0.25rem" }}>
                    <EMSSupportedBrand
                        name="GoodWe"
                        logo={ goodweLogo }
                    />
                    <EMSSupportedBrand
                        name="SolarEdge"
                        logo={ solarEdgeLogo }
                    />
                    <EMSSupportedBrand
                        name="Afore"
                        logo={ aforeLogo }
                    />
                </div>
            </div>

            <div className="container container-sm">

                <h2>
                    De voordelen van een energie management systeem
                </h2>
                <p>
                    Dankzij de automatische sturing van het energie management systeem kan je het rendement van je
                    zonnepanelen aanzienlijk verhogen. Ook ben je minder afhankelijk van het elektriciteitsnet en de
                    hoge elektriciteitsprijzen. Verder kan het energie management systeem pieken in het
                    elektriciteitsgebruik verlagen. Het zorgt ervoor dat je zo voordelig mogelijk gebruikmaakt van je
                    dynamische elektriciteitscontract.
                </p>

                <h2>
                    Contact
                </h2>
                <p>
                    Nieuwsgierig naar de mogelijkheden van een energie management systeem voor thuis of in je bedrijf?
                    Wij vertellen je graag meer over de voordelen dat ons systeem kan bieden voor jouw specifieke
                    woonsituatie. Door een offerte te laten opmaken kom je meteen te weten wat de bijbehorende kosten
                    zijn van deze slimme investering.
                </p>
                <p>
                    Heb je verder nog specifieke vragen over het implementeren van een energie management systeem? Neem
                    dan <Link to="/offerte/">contact</Link> op met ons. Smith Solar staat klaar om je te adviseren over
                    de slimme sturing van je thuisbatterij en je te begeleiden bij dit proces.
                </p>
            </div>

            <CallToAction/>

        </React.Fragment>
    );
}

export default React.memo(EMS);
