import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import MidCallToAction from "../../components/MidCallToAction";
import ProductCard from "../../components/ProductCard";

import solarEdgeCIBatterij from "../../img/battery/solaredge-ci-batterij.png";
import foxEssgMaxBatterij from "../../img/battery/foexess-g-max-batterij.png";

function ThuisbatterijZakelijk() {
    return (
        <React.Fragment>
            <Helmet
                title="Thuisbatterij zakelijk"
                description="Thuisbatterij zakelijk kopen? Slaag eigen stroom op met een thuisbatterij van Smith Solar voor duurzaam zakelijk energieverbruik. Vraag nu een offerte aan!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Thuisbatterij</h1>
                    <p>Thuisbatterij zakelijk</p>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <div className="article-open">
                    <p>
                        Steeds meer bedrijven ontdekken de voordelen van een thuisbatterij voor zakelijk gebruik. Met de groeiende behoefte aan
                        energie-efficiëntie en kostenbesparing, biedt een zakelijke thuisbatterij een uitstekende oplossing om zelfvoorzienend te
                        worden en onafhankelijk van het energienet te opereren. Of je nu een grootverbruiker bent of op zoek bent naar manieren om de
                        energiekosten binnen je bedrijf te verlagen, het zakelijk kopen van een thuisbatterij is een verstandige investering. Hiervoor
                        ben je bij Smith Solar aan het juiste adres.
                    </p>
                </div>

                <h3>Waarom een thuisbatterij zakelijk kopen?</h3>
                <p>
                    Een thuisbatterij voor zakelijk gebruik biedt talloze voordelen. Ten eerste zorgt het voor aanzienlijke kostenbesparingen door
                    opgewekte energie op te slaan en op het juiste moment te gebruiken. Daarnaast draagt het bij aan de duurzaamheid van je bedrijf
                    door gebruik te maken van hernieuwbare energiebronnen zoals zonne-energie. De verhoogde energie-efficiëntie zorgt ervoor dat je
                    bedrijf minder afhankelijk wordt van het reguliere energienet, wat de bedrijfscontinuïteit ten goede komt.
                </p>
            </div>

            <div className="container">
                <MidCallToAction />
            </div>

            <div className="container container-sm">
                <h2 className="mt-4"> Beschikbare opties voor zakelijke thuisbatterijen </h2>
                <div>
                    <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                        Thuisaccu 100 kWh en hoger
                    </h3>
                    <p>
                        Voor bedrijven met een hoog energieverbruik hebben we batterijen beschikbaar met capaciteiten van 50 kWh tot 200 kWh en zelfs
                        hoger. Deze thuisaccu's zijn ideaal voor bedrijven die veel energie nodig hebben, zoals bijvoorbeeld productiebedrijven of
                        kantoren met uitgebreide faciliteiten. Door te kiezen voor een batterij met de juiste capaciteit, kun je de energieopslag
                        optimaliseren en maximale voordelen behalen.
                    </p>
                </div>
                <div>
                    <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                        Maatwerkoplossingen
                    </h3>
                    <p>
                        Elke bedrijfssituatie is uniek, en daarom bieden wij maatwerkoplossingen aan om aan jouw specifieke energiebehoeften te
                        voldoen. Smith Solar helpt je bij het bepalen van de ideale batterijcapaciteit en configuratie, zodat je de perfecte balans
                        vindt tussen kosten en efficiëntie.
                    </p>
                </div>
                <div>
                    <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                        Handelen op de onbalans-, intraday- en EPEX-markt
                    </h3>
                    <p>
                        Een van de grote voordelen van een zakelijke thuisbatterij is de mogelijkheid om te handelen op de onbalans-, intraday- en
                        EPEX-markten. Deze markten bieden bedrijven de kans om te profiteren van fluctuaties in de energieprijzen. Door energie op te
                        slaan wanneer de prijzen laag zijn en te gebruiken of verkopen wanneer de prijzen stijgen, kun je extra inkomsten genereren en
                        je energiekosten verder verlagen.
                    </p>
                </div>
            </div>
            <div className="container container-sm">
                <h2 className="my-4">SolarEdge C&I en FoxESS G-Max batterijen</h2>
            </div>
            <div className="container">
                <ProductCard title={"SolarEdge C&I"} image={solarEdgeCIBatterij}>
                    <p>
                        De SolarEdge C&I batterij is een populaire keuze voor zakelijke toepassingen. Met zijn hoge efficiëntie en betrouwbaarheid
                        biedt deze oplossing een uitstekende manier om je energieverbruik te optimaliseren. Het systeem is gemakkelijk te integreren
                        met bestaande zonne-energie-installaties, waardoor je het meeste uit je duurzame energie kunt halen.
                    </p>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>
                <ProductCard title={"FoxESS G-Max"} image={foxEssgMaxBatterij}>
                    <p>
                        De FoxESS G-Max batterij is een andere krachtige optie, met een capaciteit van 200 kWh en een vermogen van 100 kW. Deze
                        batterij is perfect voor grotere bedrijven die behoefte hebben aan een robuuste en betrouwbare energieopslagoplossing. Of het
                        nu gaat om het verminderen van energiekosten of het waarborgen van de bedrijfscontinuïteit tijdens stroomstoringen, de FoxESS
                        G-Max biedt een uitstekende prestatie.
                    </p>
                    <Link to="/offerte/" className="btn btn-primary">
                        Offerte aanvragen
                    </Link>
                </ProductCard>
            </div>
            <div className="container container-sm">
                <div>
                    <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                        Geschikt voor grootverbruikers
                    </h3>
                    <p>
                        Met een zakelijke thuisbatterij kun je niet alleen de energiekosten binnen je bedrijf verlagen, maar ook bijdragen aan een
                        duurzamere toekomst. Neem <Link to="/contact/">contact</Link> met ons op voor meer informatie en laat ons je begeleiden naar
                        een energie-efficiënte oplossing die perfect past bij jouw zakelijke behoeften. Of vraag meteen een{" "}
                        <Link to="/offerte/">offerte</Link> aan en ontdek hoe een thuisbatterij jouw bedrijf naar een hoger niveau kan tillen.
                    </p>
                </div>
            </div>

            <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(ThuisbatterijZakelijk);
