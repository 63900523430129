import React from "react";

function NavbarCompanyLinks({ mobileOnly, onClick }) {
    return (
        <React.Fragment>
            <li className={ "nav-item" + (mobileOnly ? " nav-item-hidden-desktop-w" : " solar active") }>
                <a className="nav-link" href="https://smithsolar.nl" onClick={ onClick }>
                    Smith Solar B.V.
                </a>
            </li>
            <li className={ "nav-item" + (mobileOnly ? " nav-item-hidden-desktop-w" : " tuinbouw") }>
                <a className="nav-link" href="https://smithtuinbouwtechniek.nl" onClick={ onClick }>
                    Smith Tuinbouwtechniek B.V.
                </a>
            </li>
            <li className={ "nav-item" + (mobileOnly ? " nav-item-hidden-desktop-w" : "") }>
                <a className="nav-link" href="https://werkenbijsmith.nl" onClick={ onClick }>
                    Werken bij Smith
                </a>
            </li>
        </React.Fragment>
    );
}

export default React.memo(NavbarCompanyLinks);
