import React from "react";

import Helmet from "../components/Helmet";
import CallToAction from "../components/CallToAction";
import JumbotronCallToAction from "../components/JumbotronCallToAction";

function Subsidie() {
    return (
        <React.Fragment>
            <Helmet
                title="Subsidie"
                description="Met SDE++ subsidie steunt de overheid projecten die bijdragen aan de verlaging van de CO2-uitstoot. Daaronder vallen ook zonnepanelen (ook wel zon-pv genoemd). Maar wat vergoedt de subsidie-regeling precies en wanneer komt u in aanmerking? Hieronder staat alles wat u moet weten over de SDE++ subsidie."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zakelijke SDE Subsidie</h1>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container">

                <h2>Kies voor zonnepanelen met SDE++ subsidie</h2>

                <p>
                    Met SDE++ subsidie steunt de overheid projecten die bijdragen aan de verlaging van de CO2-uitstoot.
                    Daaronder vallen ook zonnepanelen (ook wel zon-pv genoemd). Maar wat vergoedt de subsidie-regeling
                    precies en wanneer komt u in aanmerking? Hieronder staat alles wat u moet weten over de SDE++
                    subsidie.
                </p>

                <h2>1. Wat is de SDE++ subsidieregeling?</h2>
                <p>
                    SDE staat voor Stimulering Duurzame Energieproductie en Klimaattransitie. De regeling bestaat al
                    sinds 2008 en is sindsdien twee keer verbreed. Vandaar de twee plussen. De laatste plus stamt uit
                    2020; de regeling is nu niet meer alleen bedoeld voor de opwekking van duurzame energie, maar ook
                    voor projecten waarmee CO2 wordt bespaard. Denk bijvoorbeeld aan zonnepanelen op bedrijfsdaken of op
                    overdekte parkeerterreinen.
                </p>

                <h2>2. Kan de SDE++ subsidie het hele jaar door worden aangevraagd?</h2>

                <p>
                    Nee. Er zijn 1 of 2 subsidie rondes per jaar. Dit jaar loopt de aanvraagperiode nog van 5 oktober
                    tot en met 11 november 2021. In totaal stelt de overheid per ronde 5 miljard euro beschikbaar.
                    Daarbij kan het moment waarop u uw aanvraag indient, heel bepalend zijn. De RVO hanteert binnen de
                    aanvraagperiode namelijk verschillende fases. Binnen elke fase loopt het maximale bedrag op waarvoor
                    u een aanvraag kunt indienen. Per dag worden dan alle aanvragen op volgorde van prijs gezet. Hoe
                    minder subsidie u aanvraagt per ton CO2-reductie, hoe hoger de kans op toekenning.
                </p>
                <p>
                    Als u dit jaar geen SDE++ subsidie toegekend krijgt, dan kunt u in de volgende ronde opnieuw
                    subsidie aanvragen. Smith Solar kan u adviseren over de werking van de SDE++.
                </p>

                <h2>3. Hoe werkt de SDE++ subsidie?</h2>

                <p>
                    De SDE++ vergoedt bij zonne-installaties het verschil tussen de kostprijs van de duurzame energie
                    die u opwekt en de (mogelijke) opbrengsten. U krijgt de subsidie toegekend over een periode van 15
                    jaar. De hoogte van de subsidie die u kunt krijgen, is afhankelijk van de CO₂-reductie die u met uw
                    zonne-installatie realiseert, maar óók van het moment waarop u de subsidie aanvraagt. De
                    openstellingsperiode is verdeeld in vier fases waarin de subsidiebedragen stapsgewijs hoger worden
                    (zie ook vraag 2). Het is slim om van tevoren een aanvraagstrategie uit te denken om de kans op
                    toekenning te vergroten. Smith Solar kan hierbij helpen.
                </p>

                <h2>4. Wanneer kom ik in aanmerking voor de SDE++ subsidie?</h2>
                <p>
                    Er zijn basisvoorwaarden verbonden voor het aanvragen van de SDE++ subsidie. U kunt als bedrijf of
                    instelling de subsidie aanvragen voor uw zonne-installatie als u:
                </p>
                <ul>
                    <li>
                        Een grootverbruiksaansluiting hebt of laat aanleggen (aansluiting groter 3 x 80 Ampère)
                    </li>
                    <li>
                        Voor minstens 15 kWp aan zonnepanelen (vanaf ongeveer 45 zonnepanelen, afhankelijk van het
                        aantal Wp per paneel) op uw dak wil plaatsen.
                    </li>
                </ul>

                <p>
                    Voor meer informatie, bel of schrijf naar<br/>
                    Smith Solar B.V.<br/>
                    René Grootscholten<br/>
                    <a href="mailto:rene@smithtechniek.nl">rene@smithtechniek.nl</a><br/>
                    <a href="dial:+31625289515">0625289515</a>
                </p>


            </div>

            <CallToAction/>

        </React.Fragment>
    );
}

export default Subsidie;
