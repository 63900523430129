import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Carousel
} from "react-bootstrap";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";

import slideSunset from "../../img/home/sunset.jpg";
import whiteLogo from "../../img/solar-logo-white.svg";
import banner from "../../img/solar-banner.jpg";
import slideParticulieren from "../../img/home/particulieren.jpg";
import slideBedrijven from "../../img/home/bedrijven.jpg";
import slideVerenigingen from "../../img/home/verenigingen.jpg";

function Boelhuis() {
    return (
        <React.Fragment>
            <Helmet/>

            <Carousel className="carousel carousel-home slide">
                <Carousel.Item style={{ backgroundImage: "url('" + slideSunset + "')" }}>
                    <Carousel.Caption>
                        <img src={ whiteLogo } style={{ width: "60%", maxWidth: "800px" }} alt="Smith Solar B.V."/>
                        <h1 className="slogan">Specialist in zonne-energie</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + banner + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen van Smith Solar B.V.</h1>
                        <Link to="/offerte/" className="btn btn-primary">
                            Offerte aanvragen
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideParticulieren + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor particulieren</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:particulieren" className="btn btn-primary">
                            Onze particuliere referenties
                        </a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideBedrijven + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor bedrijven</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:bedrijven"
                           className="btn btn-primary">Onze bedrijven referenties</a>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ backgroundImage: "url('" + slideVerenigingen + "')" }}>
                    <Carousel.Caption>
                        <h1>Zonnepanelen voor verenigingen</h1>
                        <a href="https://referenties.smithsolar.nl/search/type:verenigingen"
                           className="btn btn-primary">Onze verenigingen referenties</a>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <div className="container">
                <div style={{ transform: "rotate(-7deg)", textAlign: "center", margin: "60px 0" }}>
                    <p className="slogan" style={{ fontSize: "40px" }}>Besparen op uw energierekening?</p>
                </div>

                <h2>Waarom zonnepanelen?</h2>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-clock fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">4-6 jaar terugverdientijd</h4>
                                <p className="card-text">
                                    Onze prijzen zijn scherp. Daarom is het mogelijk om uw volledige zonnepanelen
                                    installatie, inclusief materiaal en montage, terug te verdienen binnen 4 tot 6 jaar!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-home fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">0% btw over zonnepanelen</h4>
                                <p className="card-text">
                                    In 2023 hoeft over zonnepanelen voor woonhuizen geen btw betaalt te worden. Ontdek
                                    via onderstaande knop hoe dit precies zit.
                                </p>
                                <Link to="/btw/" className="btn btn-primary">Meer informatie</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-map-marker-alt fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Opname op locatie</h4>
                                <p className="card-text">
                                    Wij maken een afspraak met u na uw offerte aanvraag om met u de offerte door te
                                    nemen. Ook kijken we nog extra goed naar uw dak zodat we nog aanpassingen aan de
                                    offerte kunnen maken. Dit zorgt voor een probleemloze montage.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-star fa-fw text-primary" style={{ fontSize: "60px" }}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Lees want klanten van ons zeggen</h4>
                                <p className="card-text">
                                    Klanten die u voor zijn gegaan, beoordelen ons met een <b className="b-solar">8.9</b>. Kijk wat zij van ons zeggen.
                                </p>
                                <a href="https://referenties.smithsolar.nl/reviews" className="btn btn-primary">Recensies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CallToAction title={
                <React.Fragment>
                    <small className="text-muted">
                        Bent u er klaar voor?
                    </small>
                    <br/>
                    Vraag vandaag nog een offerte aan!
                </React.Fragment>
            }/>
        </React.Fragment>
    )
}

export default Boelhuis;
