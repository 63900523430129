import React, {
    useEffect,
    useState
} from "react";
import {
    Collapse
} from "react-collapse";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function SupportQuestionAnswer({ question, children }) {
    const [opened, setOpened] = useState(false);
    const [hideTooltip, setHideTooltip] = useState(false);
    const [tooltipTextOpened, setTooltipTextOpened] = useState(false);

    useEffect(() => {
        setHideTooltip(true);
        setTimeout(() => {
            setHideTooltip(false);
            setTooltipTextOpened(opened);
        }, 500);
    }, [opened]);

    return (
        <div className="card-rounded mb-3">
            <OverlayTrigger placement="right" show={ hideTooltip ? false : undefined } overlay={
                <Tooltip id="support-question-open">
                    Klik om antwoord te { tooltipTextOpened ? "verbergen" : "tonen" }
                </Tooltip>
            }>
                <div
                    className="d-flex flex-row align-items-center mb-2 pointer-cursor"
                    onClick={ () => setOpened(!opened) }
                >
                    <div className="flex-grow-1">
                        <h3
                            className="m-0"
                            style={ { fontSize: "1.5rem" } }
                        >
                            { question }
                        </h3>
                    </div>
                    <div className="ml-2">
                        { opened ? (
                            <i className="fa-solid fa-chevron-up fa-fw"></i>
                        ) : (
                            <i className="fa-solid fa-chevron-down fa-fw"></i>
                        ) }
                    </div>
                </div>
            </OverlayTrigger>
            <Collapse isOpened={ opened }>
                <div>
                    { children }
                </div>
            </Collapse>
        </div>
    );
}

export default React.memo(SupportQuestionAnswer);
