import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import SupportQuestionAnswer from "./components/SupportQuestionAnswer";

function SupportDamage() {
    return (
        <React.Fragment>
            <Helmet
                title="Schade - Support"
                description="Heeft u een storing met uw installatie? We helpen u graag om het probleem op te lossen. Op deze pagina vind u informatie en hoe we dit kunnen oplossen."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Support</h1>
                    <p>Schade</p>
                </div>
            </div>

            <div className="container text-center my-5">
                <Link to="/support/" className="btn btn-secondary">
                    <i className="fa-solid fa-chevron-left mr-2"/>
                    Terug naar support
                </Link>
            </div>

            <div className="container container-sm">
                <h2>Veelgestelde vragen</h2>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Kapotte dakpannen na/tijdens installatie, wat nu?
                    </React.Fragment>
                }>
                    <p>
                        Bij een installatie op een dakpannen dak kan het gebeuren dat een dakpan breekt.
                        Tevens kan het zijn dat wij tijdens de montage, pannen tegenkomen die al gebroken zijn.
                        Wij adviseren u om een aantal dakpannen op voorraad te hebben. Zo kunnen wij uw dak
                        netjes achterlaten na de installatie. Mocht er na het plaatsen van de zonnepanelen een
                        lekkage aan uw dak ontstaan vragen wij u om zo spoedig mogelijk contact op te nemen:{" "}
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>{" "}
                        of bel naar{" "}
                        <a href="tel:+31174235120">0174 235 120</a>.
                    </p>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Ik wil melding maken van schade, wat te doen?
                    </React.Fragment>
                }>
                    <p>
                        Stuur ons enkele foto’s en een toelichting van de schade naar{" "}
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>{ " " }
                        dan kijken wij samen met u naar een passende oplossing.
                    </p>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Ik heb schade na een storm, wat nu?
                    </React.Fragment>
                }>
                    <p>
                        Gevallen omtrent stormschade wordt vrijwel altijd gedekt door uw opstalverzekering.
                        Stuur ons enkele foto’s en een toelichting van de schade naar{" "}
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>,
                        dan stellen wij een offerte op voor de herstelwerkzaamheden. Deze kunt u vervolgens indienen bij
                        uw verzekeraar.
                    </p>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Krassen op mijn zonnepanelen, kan dit kwaad?
                    </React.Fragment>
                }>
                    <p>
                        Krassen hebben geen invloed op de werking van uw zonnepanelen. Ieder paneel bestaat uit meerdere
                        lagen en de krassen zitten vaak in de bovenste laag. Mocht u hier toch vragen over hebben, neem dan
                        contact op met{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> en vermeld het volgende in de mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Foto's van het paneel met krassen zover u daarvan een foto kan maken.</li>
                    </ul>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Het glas van mijn zonnepaneel is gebroken
                    </React.Fragment>
                }>
                    <p>
                        Als het glas van een zonnepaneel is gebroken, neem dan contact op met{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> en vermeld het volgende in de mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Foto's van het paneel waarvan het glas is gebroken</li>
                    </ul>
                </SupportQuestionAnswer>

                <div className="text-center mt-5">
                    <h2>Staat uw vraag er niet tussen?</h2>
                    <p style={{ fontSize: "1.2rem" }}>
                        Neem dan contact op met onze support afdeling. Wij helpen u graag verder!
                        Stuur een mail naar{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> of bel naar{ " " }
                        <a href="tel:+31174235120">0174 235 120</a>.
                    </p>
                </div>

            </div>
        </React.Fragment>
    );
}

export default React.memo(SupportDamage);
