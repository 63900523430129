import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    electronicFormatIBAN,
    friendlyFormatIBAN,
    isValidIBAN
} from "ibantools";

function IBANFormatter({ iban, hideIBAN = false, tooltip = false }) {
    const cleanIban = electronicFormatIBAN(iban);
    const valid = isValidIBAN(cleanIban);
    if(tooltip) {
        if (valid) {
            return (
                <React.Fragment>
                    {hideIBAN ? "" : friendlyFormatIBAN(cleanIban)}
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="iban">IBAN geldig</Tooltip>
                    }>
                        <i className="fas fa-check ml-2 text-success"/>
                    </OverlayTrigger>
                </React.Fragment>
            )
        }
        return (
            <span className="text-warning">
                {hideIBAN ? "" : iban}
                <OverlayTrigger placement="top" overlay={
                    <Tooltip id="iban">IBAN mogelijk niet geldig</Tooltip>
                }>
                    <i className="fas fa-exclamation-triangle ml-2"/>
                </OverlayTrigger>
            </span>
        )
    } else {
        if(valid) {
            return (
                <span className="text-success">
                    <i className="fas fa-check mr-2 text-success"/>
                    IBAN geldig
                </span>
            )
        } else {
            return (
                <span className="text-warning">
                    <i className="fas fa-exclamation-triangle mr-2"/>
                    IBAN mogelijk niet geldig
                </span>
            )
        }
    }
}

export default React.memo(IBANFormatter);
