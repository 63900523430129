import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Carousel
} from "react-bootstrap";

import background from "./../../../img/producten/zonnepanelen-background.jpg";
import ulicaSolarLogo from "../../../img/brands/ulicasolar.svg";
import smithSolarLogo from "../../../img/solar-logo-white.svg";
// import dmegcLogo from "../../../img/brands/dmegc-white.svg";

function ProductenZonnepanelenCarousel() {
    return (
        <Carousel className="carousel carousel-product slide" style={{ backgroundImage: "url('" + background + "')" }} wrap>
            <Carousel.Item>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ ulicaSolarLogo } alt="Ulica Solar"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>Ulica Solar Zonnepanelen</h3>
                            <p>Wij leveren standaard Ulica Solar <b>430 Wp Full Black</b> en <b>405 Wp Full Black</b>.
                            </p>
                            <ul>
                                <li><b>Meest gekozen</b> optie door klanten van Smith Solar B.V. vanwege;</li>
                                <li>Duurzaam en hoge kwaliteit voor lage prijzen.</li>
                                <li>Keuze uit volledig zwarte of Glas-Glas panelen.</li>
                                <li><b>12 jaar fabrieksgarantie</b>.</li>
                                <li>Opbrengstgarantie* van <b>90% na 10 jaar</b> en <b>80% na 25 jaar</b>.</li>
                            </ul>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <Link to="/producten/ulica-solar/" className="btn btn-primary">Meer informatie</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ smithSolarLogo } alt="Ulica Solar"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>Smith Solar Zonnepanelen</h3>
                            <p>Wij hebben eigen huismerk zonnepanelen van hoge kwaliteit beschikbaar van ~430 Wp!</p>
                            <ul>
                                <li>Duurzaam en hoge kwaliteit voor lage prijzen.</li>
                                <li>Keuze uit volledig zwarte of Glas-Glas panelen.</li>
                                <li><b>15 jaar fabrieksgarantie</b>.</li>
                                <li>Opbrengstgarantie* van <b>90% na 20 jaar</b> en <b>87,4% na 30 jaar</b>.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            {/*<Carousel.Item>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-6">*/}
            {/*                <div className="product-container-logo text-center">*/}
            {/*                    <img src={ dmegcLogo } alt="DMEGC"/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6 product-container-text">*/}
            {/*                <h3>DMEGC Zonnepanelen</h3>*/}
            {/*                <p>Wij leveren standaard DMEGC <b>glas/glas panelen van 325 Wp</b>.</p>*/}
            {/*                <ul>*/}
            {/*                    <li>Glas-glas panelen voor langere garantie en betere duurzaamheid.</li>*/}
            {/*                    <li>Full black panelen.</li>*/}
            {/*                    <li><b>15 jaar fabrieksgarantie</b>.</li>*/}
            {/*                    <li>Opbrengstgarantie* van <b>90% na 10 jaar</b> en <b>80% na 30 jaar</b>.</li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Carousel.Item>*/}
        </Carousel>
    )
}

export default ProductenZonnepanelenCarousel;
