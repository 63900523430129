import React from "react";

function ProductCard({ children, title, image }) {
    return (
        <div className="card-rounded mb-3">
            <div className="row">
                <div className="col-md-8">
                    <h3>
                        { title }
                    </h3>
                    { children }
                </div>
                <div className="col d-flex justify-content-center">
                    <img
                        src={ image }
                        alt={ title }
                        className="img-fluid"
                        style={{ maxHeight: "250px" }}
                    />
                </div>
            </div>
        </div>
    );
}

export default React.memo(ProductCard);
