import React, {
    useMemo
} from "react";
import {
    Link,
    useRouteMatch
} from "react-router-dom";

function NavbarLink({
                        to,
                        title,
                        exact = false,
                        hidden = false,
                        onClick,
                        className,
                        children,
                        active,
}) {
    const match = useRouteMatch({
        path: to,
        exact: exact
    });
    const classNames = useMemo(() => {
        let classNamesArray = ["nav-item"];
        if(match || active) {
            classNamesArray.push("active");
        }
        if(hidden) {
            classNamesArray.push("d-none");
        }
        if(className) {
            classNamesArray.push(className);
        }
        return classNamesArray.join(" ");
    }, [className, hidden, match, active]);
    return (
        <li className={ classNames } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { children ?? title }
            </Link>
        </li>
    );
}

export default React.memo(NavbarLink);
