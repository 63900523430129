import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Carousel
} from "react-bootstrap";

import background from "./../../../img/producten/omvormers-background.jpg";
import aforeLogoWhite from "../../../img/brands/afore-white.svg";
import solarEdgeLogoWhite from "../../../img/brands/solaredge-white.svg";
import enphaseLogo from "../../../img/brands/enphase-white.svg";

function ProductenOmvormersCarousel() {
    return (
        <Carousel className="carousel carousel-product slide" style={{ backgroundImage: "url('" + background + "')" }} wrap>
            <Carousel.Item>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ solarEdgeLogoWhite } alt="SolarEdge"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>SolarEdge Omvormers</h3>
                            <ul>
                                <li>Oplossing van de hoogste kwaliteit.</li>
                                <li>Superieur rendement van <b>97,6%</b>.</li>
                                <li>Schaduw is nooit meer een probleem d.m.v. optimizers onder alle panelen.</li>
                                <li>Inzicht in opbrengsten per zonnepaneel.</li>
                                <li><b>12 jaar garantie</b> op de omvormers en <b>25 jaar garantie</b> op alle
                                    optimizers.
                                </li>
                                <li>Smith Solar B.V. is SolarEdge Installer Plus</li>
                            </ul>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <Link to="/producten/solaredge/" className="btn btn-primary">Meer informatie</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ aforeLogoWhite } alt="Afore"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>Afore Omvormers</h3>
                            <ul>
                                <li>Simpele en goedkope oplossing van hoge kwaliteit.</li>
                                <li>Omvormers voor de kleinste installatie tot grote installaties.</li>
                                <li><b>10 jaar garantie</b>.</li>
                            </ul>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <Link to="/producten/afore/" className="btn btn-primary">Meer informatie</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="product-container-logo text-center">
                                <img src={ enphaseLogo } alt="Enphase"/>
                            </div>
                        </div>
                        <div className="col-md-6 product-container-text">
                            <h3>Enphase Micro-Omvormers</h3>
                            <ul>
                                <li>Makkelijke installatie voor complexe daken</li>
                                <li>Eenvoudig uitbreiden in de toekomst</li>
                                <li><b>25 jaar garantie op micro-omvormers</b>.</li>
                            </ul>
                            <div className="btn-toolbar">
                                <div className="btn-group mr-2">
                                    <Link to="/producten/enphase/" className="btn btn-primary">Meer informatie</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    )
}

export default ProductenOmvormersCarousel;
