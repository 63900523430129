import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import MidCallToAction from "../../components/MidCallToAction";
import ZonnepanelenSchuur from "../../img/zonnepanelen/solar-zonnepanelen-schuurdak-min.jpg";

function ZonnepanelenTuinders() {
    return (
        <React.Fragment>
            <Helmet
                title="Tuinders"
                description="Zonnepanelen laten plaatsen op een kas of schuur? Smith Solar is specialist in groene energie en biedt maatwerk oplossingen. Vraag nu een offerte aan!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <p>Zonnepanelen voor tuinders</p>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <div className="article-open">
                    <p>
                        Als tuinder ben je voortdurend op zoek naar manieren om de duurzaamheid van je bedrijf te verbeteren en tegelijkertijd de
                        kosten te verlagen. Bij Smith Solar B.V. zijn we gespecialiseerd in groene energie, waaronder zonnepanelen. Zo bieden wij
                        innovatieve en op maat gemaakte oplossingen voor het gebruik van zonnepanelen in kassen en op schuren. Lees verder en ontdek
                        hoe onze duurzame energieoplossingen jou kunnen helpen om je tuinderij te optimaliseren.
                    </p>
                </div>

                <h3>Kas verwarmen met frameless zonnepanelen</h3>
                <p>
                    In ons assortiment hebben we speciaal geproduceerde zonnepanelen voor tuinders om te installeren op kasdekken, namelijk frameless
                    zonnepanelen. Hierbij verwijderen we het glas uit de kas en plaatsen we het frameless zonnepaneel terug.
                </p>

                <h4 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Wat zijn de voordelen van frameless zonnepanelen?
                </h4>
                <ul>
                    <li>Frameless zonnepanelen zorgen voor een strak uiterlijk.</li>
                    <li>De belasting op de bestaande constructie van de kas wordt niet verzwaard.</li>
                    <li>De lichtdoorlaatbaarheid van de zonnepanelen kan op elk gewenst niveau worden gebracht.</li>
                    <li>De frameless zonnepanelen zijn onder meer verkrijgbaar in transparante en semi-transparante uitvoeringen.</li>
                </ul>
            </div>

            <div className="container">
                <MidCallToAction />
            </div>

            <div className="container container-sm">
                <h3>Eenvoudig monteren van zonnepanelen boven de kas</h3>
                <p>Smith Solar B.V. heeft een eigen systeem ontwikkeld om snel en eenvoudig zonnepanelen te monteren boven een kas.</p>
            </div>
            <div className="container mb-5">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <h4>Speciale constructie bovenop de kas</h4>
                        <p>
                            Het monteren van de zonnepanelen boven op een kas doen we door middel van een speciale constructie, waar we de panelen op
                            bevestigen.
                        </p>

                        <h4>Flexibel ontwerp</h4>
                        <p>Ieder paneel past netjes op de constructie, waardoor het zeer flexibel is.</p>

                        <h4>In-house geproduceerd</h4>
                        <p>We produceren het systeem in-house, zodat de kwaliteit en compatibiliteit verzekerd is.</p>
                    </div>
                    <div className="col-md-5">
                        <img src={ZonnepanelenSchuur} className="img-fluid" alt="Zonnepanelen op schuurdak" />
                    </div>
                </div>
            </div>
            <div className="container container-sm">
                <h3>Zonnepanelen op schuur plaatsen</h3>
                <p>
                    Bij Smith Solar hebben we ook veel ervaring in het installeren van zonnepanelen op een schuur. Hiervoor gebruiken we het duurzame
                    en sterke montagesysteem <Link to="/producten">ValkPitched - Trapezoidal</Link>, waarmee we een betrouwbare bevestiging van de
                    zonnepanelen garanderen. Of het nu gaat om een klein familiebedrijf of een grootschalige tuinderij, wij bieden oplossingen die
                    perfect aansluiten bij de behoeften van jouw bedrijf. Onze ervaren installateurs zorgen voor een vakkundige en efficiënte
                    installatie, zodat je snel kunt profiteren van de voordelen van zonne-energie binnen je organisatie.
                </p>

                <h3>Een duurzame toekomst voor jouw tuinderij</h3>
                <p>
                    Duurzaamheid is een belangrijke factor voor de toekomst van de tuinbouwsector. Daarom streven we ernaar om onze klanten te
                    voorzien van hoogwaardige en milieuvriendelijke energieoplossingen.
                </p>

                <p>
                    Wil je meer weten over het verwarmen van een kas met zonnepanelen of over het installeren van zonnepanelen op een schuur? Bij
                    Smith Solar staan we klaar om je te helpen bij het realiseren van een duurzame toekomst voor jouw tuinbouwbedrijf. Neem vandaag
                    nog <Link to="/contact/">contact</Link> op met ons voor advies op maat.
                </p>
            </div>

            <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(ZonnepanelenTuinders);
