import React from "react";
import {
    createRoot
} from "react-dom/client";

import * as Sentry from "@sentry/react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import axios from "axios";
import { stringify } from "qs";

import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

import Hotjar from "@hotjar/browser";

import ReactPixel from "react-facebook-pixel";

import { clarity } from "clarity-js";

import { permissionsToGAConsents } from "./components/CookieConsentBanner";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://09da92c4d3fe4c24836ff7da4ca77e87@sentry1.zsnode.com/9",
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

// Start Hotjar
const hotjarSiteId = 3818553;
const hotjarVersion = 6;
Hotjar.init(hotjarSiteId, hotjarVersion);

// Start Microsoft Clarity
clarity.start({
    projectId: "lcx4mojg4y",
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true
});

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

if(development) {
    axios.defaults.baseURL = "/v1/";
} else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/v1/" : "https://dev.api.smithsolar.nl/v1/";
}

["default", "tpm"].forEach((name) => {
    const gaConsentDefault = permissionsToGAConsents({});
    ReactGA.gtag("consent", name, gaConsentDefault);
});
ReactGA.initialize(
    [
        { trackingId: "UA-65727447-2", gaOptions: { name: "default" } },
        { trackingId: "DC-8979034", gaOptions: { name: "tpm" } }
    ]
);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
ReactGA.ga('tpm.send', 'event', 'conversion', {
    'allow_custom_scripts': true,
    'send_to': 'DC-8979034/pagev0/homep0+standard'
});

TagManager.initialize({
    gtmId: "GTM-P2TGJTC"
});

ReactPixel.init("757421105954324");
ReactPixel.revokeConsent();

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Sentry.ErrorBoundary>
        <App/>
    </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
