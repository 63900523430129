import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import SupportQuestionAnswer from "./components/SupportQuestionAnswer";
import SupportWidget from "../../components/support/SupportWidget";

function SupportMonitoring() {
    return (
        <React.Fragment>
            <Helmet
                title="Monitoring - Support"
                description="Heeft u een storing met uw installatie? We helpen u graag om het probleem op te lossen. Op deze pagina vind u informatie en hoe we dit kunnen oplossen."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Support</h1>
                    <p>Monitoring</p>
                </div>
            </div>

            <div className="container text-center my-5">
                <Link to="/support/" className="btn btn-secondary">
                    <i className="fa-solid fa-chevron-left mr-2"/>
                    Terug naar support
                </Link>
            </div>

            <div className="container container-sm">
                <h2>Veelgestelde vragen</h2>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Wanneer krijg ik mijn monitoring portal inloggegevens?
                    </React.Fragment>
                }>
                    <p>
                        Dit verschilt per installatie, in de meeste gevallen 3 tot 5 werkdagen. Heeft u na 5 werkdagen
                        nog steeds niets ontvangen, neem hiervoor dan contact op:{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>
                        { " of " }
                        <a href="tel:+31174235120">0174 235 120</a>
                    </p>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Ik heb een nieuwe WiFi router, hoe verbind ik deze met mijn omvormer?
                    </React.Fragment>
                }>
                    <p>
                        Geeft je monitoring portal geen opbrengst weer? Dan is er in de meeste gevallen geen
                        communicatie meer tussen de omvormer en de router, de zonnepanelen leveren dan wel
                        productie, maar de gegevens zijn niet meer te zien in het monitoringsportal.
                    </p>
                </SupportQuestionAnswer>

            </div>

            <div className="container text-center mt-5 mb-3">
                <h2>Heeft u problemen met uw wifi?</h2>
                <p className="text-muted" style={ { fontSize: "1.5rem" } }>Dat lossen we samen op!</p>
            </div>
            <div className="container text-center">
                <div className="card-rounded">
                    <SupportWidget/>
                </div>
            </div>

            <div className="container container-sm">
                <div className="text-center mt-5">
                    <h2>Staat uw vraag er niet tussen?</h2>
                    <p style={ { fontSize: "1.2rem" } }>
                        Neem dan contact op met onze support afdeling. Wij helpen u graag verder!
                        Stuur een mail naar{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> of bel naar{ " " }
                        <a href="tel:+31174235120">0174 235 120</a>.
                    </p>
                </div>
            </div>

        </React.Fragment>
    );
}

export default React.memo(SupportMonitoring);
