import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/growatt.svg";

function Growatt() {
    return (
        <Monitoring
            brandName="Growatt"
            logoUrl={ logo }
            desktopUrl="https://server.growatt.com/login"
            iosUrl="https://apps.apple.com/nl/app/shinephone/id669936054"
            androidUrl="https://play.google.com/store/apps/details?id=com.growatt.shinephones"
        />
    )
}

export default Growatt;
