import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";

function BTWTerugvraagBedankt() {
    return (
        <React.Fragment>
            <Helmet title="Bedankt"/>

            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>BTW Terugvraag</h1>
                </div>
            </div>
            <div className="container mb-5">
                <div className="text-center mb-5">
                    <h1>BTW Terugvraag ingediend!</h1>
                    <p>Wij gaan zo spoedig mogelijk aan de slag met uw terugvraag.</p>
                </div>
                <div className="card mb-5">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-envelope fa-fw text-primary" style={{ fontSize: "60px" }}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">
                                    Post van de Belastingdienst
                                </h4>
                                <p className="card-text">
                                    Als u post ontvangt van de Belastingdienst over het terugvragen van de BTW op uw
                                    zonnepanelen installatie, <b>dan willen wij deze post graag in ontvangst nemen</b> om het proces voort te
                                    zetten. Van de roze formulieren hebben wij het orgineel met de blauwe envelop nodig. Overige post
                                    kan via een scan per mail verstuurd worden aan <a href="mailto:btwterugvraag@smithsolar.nl">btwterugvraag@smithsolar.nl</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <Link to="/btwterugvraag/" className="btn btn-primary">
                        Terug naar uitleg
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(BTWTerugvraagBedankt);
