import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";

function OfferteDeurTotDeurBedankt() {
    return (
        <React.Fragment>
            <Helmet
                title="Bedankt"
                canonicalUrls={ [
                    "https://smithsolar.nl/offerte/bedankt/",
                    "https://smithsolar.nl/offerte/bedankt/zonnepanelen/",
                    "https://smithsolar.nl/offerte/bedankt/thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/zonnepanelen-thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/laadpaal/",
                    "https://smithsolar.nl/offerte/bedankt/ems/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/zonnepanelen/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/zonnepanelen-thuisbatterij/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/laadpaal/",
                    "https://smithsolar.nl/offerte/bedankt/deur-tot-deur/ems/",
                ] }
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Offerte aan de deur</h1>
                </div>
            </div>
            <div className="container mb-5">
                <div className="text-center">
                    <h1>Bedankt voor uw offerte aanvraag!</h1>
                    <p>Wij gaan zo spoedig mogelijk contact met u opnemen.</p>
                    <Link
                        to="/offerte/deur-tot-deur/"
                        className="btn btn-primary btn-sm mt-5"
                    >
                        Terug naar offerte aanvragen
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(OfferteDeurTotDeurBedankt);
