import React from 'react';
import {
    BrowserRouter as Router
} from "react-router-dom";

import SolarRouter from "./SolarRouter";
import { ThemeManager } from "./context/ThemeContext";

import "./scss/main.scss";

function App() {
    return (
        <Router>
            <ThemeManager>
                <SolarRouter/>
            </ThemeManager>
        </Router>
    );
}

export default App;
