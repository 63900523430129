import React from "react";

import Monitoring from "../../components/pages/monitoring/Monitoring";

function Kstar() {
    return (
        <Monitoring
            brandName="KSTAR"
            desktopUrl="https://globalhome.solarmanpv.com/login"
            iosUrl="https://apps.apple.com/nl/app/solarman-smart/id1469487897"
            androidUrl="https://play.google.com/store/apps/details?id=com.igen.xiaomaizhidian&gl=NL"
        />
    )
}

export default React.memo(Kstar);
