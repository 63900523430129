import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/solis.svg";
import solisInvertersManual from "../../files/manuals/solis-wifi-manual.pdf";

function Solis() {
    return (
        <Monitoring
            brandName="Solis"
            logoUrl={ logo }
            desktopUrl="https://m.ginlong.com/"
            iosUrl="https://apps.apple.com/nl/app/solis-home/id1320723951"
            androidUrl="https://play.google.com/store/apps/details?id=com.ginlong.home&hl=nl"
            manual={ solisInvertersManual }
        />
    )
}

export default Solis;
