import React, {
    useMemo,
    useRef
} from "react";

import useScrollPosition from "../../hooks/ScrollHook";
import useWindowSize from "../../hooks/WindowSize";

function SubNavbar({ children }) {
    const navbarRef = useRef(null);
    const scrollPosition = useScrollPosition();
    const windowSize = useWindowSize();

    const classNames = useMemo(() => {
        return ["navbar", "subnavbar", "navbar-expand-lg", "navbar-light", "bg-light"];
    }, []);

    const mobile = windowSize.width < 992;
    const mobileSmall = windowSize.width < 576;

    const secondHeight = mobile ? 0 : 26;
    const mainHeight = mobileSmall ? 80 : 126;
    const topPosition = Math.max(secondHeight + mainHeight - scrollPosition.y, mobileSmall ? 80 : 86);

    return (
        <React.Fragment>
            <nav
                ref={navbarRef}
                className={classNames.join(" ")}
                style={{
                    position: "fixed",
                    top: topPosition + "px",
                    width: "100%",
                    zIndex: 1029,
                    borderTop: "1px solid lightgray",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.4)",
                }}
            >
                <div className="container-fluid">
                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto"/>
                        <ul className="navbar-nav">
                            {children}
                        </ul>
                    </div>
                </div>
            </nav>
            <div
                style={{
                    height: "57px"
                }}
            />
        </React.Fragment>
    );
}

export default React.memo(SubNavbar);
