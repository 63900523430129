import React from "react";

import Offerte from "./Offerte";

function OfferteDeurTotDeur() {
    return (
        <React.Fragment>
            <Offerte
                customTitle="Offerte aan de deur"
                customSource="all-star-sales"
                thanksUrl="/offerte/deur-tot-deur/bedankt"
            />
        </React.Fragment>
    );
}

export default React.memo(OfferteDeurTotDeur);
