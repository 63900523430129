import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";

function MidCallToAction({ className }) {
    const classNames = useMemo(() => {
        let classes = ["mid-calltoaction"];
        if(className) {
            classes.push(className);
        }
        return classes.join(" ");
    }, [className]);
    return (
        <div className={ classNames }>
            <div className="flex-grow-1">
                <h2>Vraag een offerte aan voor een oplossing op maat!</h2>
            </div>
            <div className="d-flex justify-content-center">
                <Link to="/offerte/" className="btn btn-primary">
                    Offerte aanvragen
                </Link>
            </div>
        </div>
    )
}

export default React.memo(MidCallToAction);
