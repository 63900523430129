import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";
import SupportAforeAlert from "../../components/support/SupportAforeAlert";

import logo from "./../../img/brands/afore.svg";
import aforeNewInvertersManual from "../../files/manuals/afore-wifi-solarman-inverters-manual.pdf";

function AforeSolarman3() {
    return (
        <Monitoring
            brandName="Afore Solarman 3"
            logoUrl={ logo }
            desktopUrl="https://globalhome.solarmanpv.com/login"
            iosUrl="https://apps.apple.com/nl/app/solarman-smart/id1469487897"
            androidUrl="https://play.google.com/store/apps/details?id=com.igen.xiaomaizhidian&gl=NL"
            manual={ aforeNewInvertersManual }
            alert={
                <SupportAforeAlert noButton/>
            }
        />
    )
}

export default React.memo(AforeSolarman3);
