import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";

function MonitoringVerschilEnergieleverancier() {
    return (
        <React.Fragment>
            <Helmet title="Verschil tussen monitoring en energieleverancier"/>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <p>Verschil tussen monitoring en energieleverancier</p>
                </div>
            </div>
            <div className="container container-sm mb-5">
                <div className="article-open">
                    <p>
                        U krijgt het maandoverzicht van uw energieleverancier waarop staat hoeveel energie u heeft
                        verbruikt en teruggeleverd in de afgelopen maand. U kijkt vervolgens op uw monitoring app van
                        uw zonnepanelen en daar staat een veel hoger aantal kWh!
                    </p>
                </div>
                <h2>Hoe komt dit?</h2>
                <p>
                    Elektriciteit zoekt altijd de kortste weg naar energieverbruikers. In het geval dat uw zonnepanelen
                    zijn aangesloten op de wasmachine groep en uw wasmachine staat aan, dan gaat opgewekte energie als
                    eerste de wasmachine in voordat het naar andere apparaten toe gaat.
                </p>
                <p>
                    Uw eigen apparaten zitten altijd dichter bij de zonnepanelen dan de apparaten van uw buren. Dus zal
                    alleen extra opgewekte energie door uw energiemeter heen gaan, oftewel, teruggeleverd worden.
                </p>
                <p>
                    De energiemeter kan alleen meten hoeveel energie er door de meter heen gaat. Dus opgewekte energie
                    die direct een apparaat in gaat kan de energiemeter niet meten, en dus is dit niet zichtbaar op het
                    maandoverzicht.
                </p>

                <h2>Uitrekenen direct-energieverbruik</h2>
                <p>
                    U kunt dus met het maandoverzicht van uw energieleverancier en de data uit het monitoringplatform
                    uitrekenen hoeveel direct-energieverbruik u heeft:
                </p>
            </div>

            <div className="container mb-5">
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                    <b>
                        Opgewekte energie (monitoring) - Teruggeleverd (energieleverancier) = Direct energieverbruik
                    </b>
                </p>
            </div>

            <div className="container container-sm text-center mb-5">
                <p>
                    Als u meer uitleg wilt of nog vragen heeft kunt u contact met ons opnemen.
                </p>

                <Link to="/contact/" className="btn btn-primary btn-sm">
                    Contact opnemen
                </Link>
            </div>
        </React.Fragment>
    );
}

export default React.memo(MonitoringVerschilEnergieleverancier);
