import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

function SolarEdgeLaadpaal() {
    return (
        <React.Fragment>
            <Helmet
                title="SolarEdge EV-lader"
                description="Laadpaal aanschaffen? Ontdek de SolarEdge-laadpaal bij Smith Solar en stap vandaag nog over op duurzaam opladen. Contacteer ons voor meer info!"
            />

            {/*<LaadpaalSubNavbar/>*/}

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>SolarEdge EV-lader</h1>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <div className="article-open">
                    <p>
                        Wil jij je wagen op een duurzame en efficiënte manier opladen bij je thuis? Dan is de SolarEdge EV-lader een uitstekende keuze
                        voor jou! Deze laadpaal laadt je auto op door middel van eigen zonne-energie en biedt daarnaast een naadloze integratie met je
                        zonne-energiesysteem. Voor het aanschaffen van een SolarEdge-laadpaal en advies omtrent deze laadoplossing ben je bij Smith
                        Solar B.V. aan het goede adres.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="properties-container">
                    <div className="container container-sm">
                        <h2>Eigenschappen van de SolarEdge-laadpaal</h2>
                        <p className="description">
                            De SolarEdge EV-lader is een slimme, duurzame en gebruiksvriendelijke laadoplossing voor thuis. De laadpaal maakt
                            automatisch gebruik van de beschikbare zonne-energie voor het opladen van je elektrische wagen. Zo kun je thuis snel je
                            auto laden met schone, goedkope en hernieuwbare energie. Hieronder ontdek je alle eigenschappen van de
                            SolarEdge-autolader:
                        </p>
                    </div>
                    <div className="properties-grid">
                        <div>
                            <h3>Efficiënt laden met 1 of 3 fase aansluiting</h3>
                            <p>
                                Met de SolarEdge EV-lader kun je jouw elektrische auto opladen met een vermogen variërend van 4,6 tot 22 kW, geschikt
                                voor zowel 1 fase als 3 fase aansluitingen. Dit biedt flexibiliteit en efficiëntie, zodat je altijd de snelheid van
                                laden kunt kiezen die het beste bij jouw behoeften past.
                            </p>
                        </div>
                        <div>
                            <h3>Flexibele opties voor laadkabels</h3>
                            <p>
                                Bij de SolarEdge-laadpaal heb je de keuze tussen een vaste of losse laadkabel, afhankelijk van jouw voorkeur en
                                behoeften. Zo heb je de vrijheid om jouw laadsysteem aan te passen aan je specifieke situatie, waardoor het opladen
                                nog eenvoudiger wordt.
                            </p>
                        </div>
                        <div>
                            <h3>Opladen met eigen zonne-energie</h3>
                            <p>
                                Dankzij de integratie met de SolarEdge-omvormer kun je jouw elektrische auto opladen met je eigen opgewekte
                                zonne-energie. Deze duurzame en kosteneffectieve energiebronnen zorgen ervoor dat je geld bespaart en bijdraagt aan
                                een schonere planeet.
                            </p>
                        </div>
                        <div>
                            <h3>Alles in één app beheren</h3>
                            <p>
                                Via de mySolarEdge app heb je volledige controle over jouw zonnepanelen, batterijopslag en laadpaal, allemaal vanuit
                                één handige app. Monitor energieverbruik, bekijk laadstatistieken en optimaliseer eigen energiegebruik met gemak.
                            </p>
                        </div>
                        <div>
                            <h3>Dynamic load balancing</h3>
                            <p>
                                Door het vermogen voor het laden van je elektrische wagen dynamisch aan te passen via de SolarEdge-omvormer en Inline
                                Energy Meter, bescherm je de hoofdschakelaar van je huis tegen uitschakeling door overstroom. Hierdoor blijft het
                                gehele systeem in balans.
                            </p>
                        </div>
                        <div>
                            <h3>Laaddata exporteren naar Excel</h3>
                            <p>
                                Met de mogelijkheid om laaddata te exporteren naar Excel, kun je jouw laadkosten nauwkeurig bijhouden en doorberekenen
                                aan jouw werkgever. Dit biedt transparantie en zorgt ervoor dat je altijd op de hoogte bent van jouw energieverbruik
                                en kosten.
                            </p>
                        </div>
                        <div>
                            <h3>Eenvoudig activeren en beheren</h3>
                            <p>
                                Start jouw laadsessies moeiteloos met behulp van een RFID kaart of via de handige app van SolarEdge. Met slechts een
                                paar tikken op jouw smartphone ben je klaar om jouw elektrische auto op te laden.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container container-sm">
                <div className="card-rounded">
                    <h2>Neem contact op met ons</h2>
                    <p>
                        Voor meer informatie over de SolarEdge EV-lader kun je vrijblijvend <Link to="/contact/">contact</Link> opnemen met ons. Bij
                        Smith Solar vertellen we je alles over deze duurzame en efficiënte laadoplossing en geven we je graag advies op maat,
                        afgestemd op jouw specifieke behoeften.
                    </p>
                    <Link to="/contact/" className="btn btn-primary">
                        Neem contact op
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(SolarEdgeLaadpaal);
