import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/solax.svg";

function Solax() {
    return (
        <Monitoring
            brandName="SolaX"
            logoUrl={ logo }
            desktopUrl="https://solaxcloud.com"
            iosUrl="https://apps.apple.com/nl/app/solaxcloud/id1300059673"
            androidUrl="https://play.google.com/store/apps/details?id=com.solaxcloud.starter"
        />
    )
}

export default Solax;
