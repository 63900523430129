import React from "react";
import {
    CardDeck
} from "react-bootstrap";
import {
    Link
} from "react-router-dom";
import Helmet from "../../components/Helmet";

import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

import gebruikTerugleveren from "../../img/zonnepanelen/gebruik-terugleveren.svg"
import gebruikOpslaan from "../../img/zonnepanelen/gebruik-opslaan.svg"

import mono from "../../img/zonnepanelen/zonnepanelen-mono.jpg";
import poly from "../../img/zonnepanelen/zonnepanelen-poly.jpg";

function Producten() {
    return (
        <React.Fragment>
            <Helmet
                title="Over zonnepanelen"
                description="Zonnepanelen kopen doe je niet zomaar. Een zonnepaneel gaat meer dan 25 jaar mee. Dat wil je goed voorbereiden. Hier vind je alle informatie over zonnepanelen."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container">

                <h2>Alles wat je weten wilt over zonnepanelen</h2>
                <p><b>Zonnepanelen kopen doe je niet zomaar. Een zonnepaneel gaat meer dan 25 jaar mee. Dat wil je goed
                    voorbereiden. Hier vind je alle informatie over zonnepanelen.</b></p>
                <p>De technologie van zonnepanelen is vernieuwd, de prijzen van zonnepanelen zijn gedaald, er is
                    subsidie en je
                    kunt zonnepanelen op meer manieren toepassen. Nu is het de tijd om zonnepanelen te kopen.</p>

                <h2>Wat zijn zonnepanelen?</h2>
                <p>Zonnepanelen zijn opgebouwd uit zonnecellen, die het zonlicht opvangen en omzetten in bruikbare
                    elektriciteit. De opgewekte elektriciteit kun je:</p>

                <CardDeck className="mb-2">
                    <div className="card">
                        <img className="card-img-top" src={ gebruikTerugleveren } alt="Terugleveren van energie"/>
                        <div className="card-body">
                            <h5 className="card-title">Netgekoppeld</h5>
                            <p className="card-text">
                                In Nederland wordt dit systeem het meest gebruikt. Bij dit systeem wordt uw omvormer
                                direct op het electriciteitsnet aangesloten. U zult zelf eerst de opgewekte energie
                                gebruiken. Eventuele extra energie zal worden teruggeleverd aan uw
                                energieleverancier.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src={ gebruikOpslaan } alt="Opslaan van energie"/>
                        <div className="card-body">
                            <h5 className="card-title">In een accu opslaan</h5>
                            <p className="card-text">
                                Bij dit systeem zal de opgewekte energie in een accu worden opgeslagen. Dit kan
                                handig zijn als er geen electriciteitsnet beschikbaar is, bijvoorbeeld met een boot,
                                caravan of camper. Eventuele opgewekte energie zal u eerst verbruiken voordat het
                                opgeslagen wordt.
                            </p>
                        </div>
                    </div>
                </CardDeck>

                <h2>Hoe zien zonnepanelen er uit?</h2>
                <p>
                    Zonnepanelen hebben aan de voorkant vaak een glasplaat. Aan de achterkant zijn ze ondoorzichtig. Al
                    bestaan er ook ’doorzicht’ zonnepanelen, waarin de zonnecellen tussen twee glasplaten zitten. Het
                    zonnepaneel laat ook licht door en is deels doorzichtig.
                </p>
                <p>
                    De zonnecellen worden gemaakt van silicium, een halfgeleidermateriaal. Dat silicium bestaat uit twee
                    lagen. Onder invloed van licht gaat er tussen de twee lagen een elektrische stroom lopen. Silicium
                    wordt naast zonnepanelen ook gebruikt in pc’s en audio- en video apparatuur.
                </p>

                <h2>Type zonnepanelen</h2>
                <p>
                    Zonnepanelen worden ook wel PV panelen genoemd. PV is een afkorting van het Engelse woord ’Photo
                    Voltaic’. Dit betekent ‘licht’(=Photo) en ‘elektriciteit’ (Voltaic). Er zijn 2 soorten:
                </p>
                <CardDeck className="mb-2">
                    <div className="card">
                        <img className="card-img-top" src={ mono } alt="Mono zonnepanelen"/>
                        <div className="card-body">
                            <h5 className="card-title">Monokristallijne zonnepanelen</h5>
                            <p className="card-text">
                                Deze hebben het hoogste rendement. Een goede keuze als je beperkte ruimte hebt. Dit
                                soort panelen worden vanwege hun zwarte design het meest gekozen door particulieren.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <img className="card-img-top" src={ poly } alt="Poly zonnepanelen"/>
                        <div className="card-body">
                            <h5 className="card-title">Polykristallijne zonnepanelen</h5>
                            <p className="card-text">
                                Deze bieden een redelijk hoog rendement en zijn gunstig in prijs. Heb je voldoende
                                ruimte op je dak, dan is dit de meest gangbare optie. Dat is ook de reden waarom
                                deze panelen vaak gekozen worden door bedrijven met een groot dak.
                            </p>
                        </div>
                    </div>
                </CardDeck>
                <Link to="/zonnepanelen/mono-of-poly/" className="btn btn-primary">Meer informatie</Link>
                <br/><br/>

                <h2>Levensduur zonnepanelen</h2>
                <div className="row">
                    <div className="col-md-8">
                        <p>
                            Belangrijk bij zonnepanelen: de levensduur. Zonnepanelen hebben een gegarandeerde levensduur
                            van tenminste 25 jaar. In praktijk ligt, afhankelijk van de kwaliteit, de levensduur tussen
                            de 25 en 30 jaar. De levensduur van zonnepanelen op basis van amorf silicium ligt tussen de
                            5 en 15 jaar.
                        </p>
                    </div>
                    <div className="col-md-4 text-center">
                        <b className="text-primary" style={{ fontSize: "40px" }}>
                            <i className="fas fa-stopwatch"/> 25 jaar
                        </b>
                        <br/>
                    </div>
                </div>

                <h2>Hoe werken zonnepanelen?</h2>
                <p>
                    De werking van zonnepanelen is eigenlijk eenvoudig: zonnecellen vangen licht op en zetten dit om in
                    elektrische energie. Die elektriciteit is gelijkstroom; de omvormer zet de gelijkstroom om in
                    wisselstroom. De opgewekte elektriciteit kan zo direct in huis worden gebruikt. Als je meer
                    elektriciteit opwekt dan je verbruikt, kun je de overtollige energie terugleveren aan het openbare
                    elektriciteitsnet. Dan loopt je elektriciteitsmeter terug. Je gaat flink besparen op je
                    energiekosten. Zonnecellen, de basis van zonnepanelen, bestaan uit twee lagen. Onder invloed van
                    licht krijgt de ene laag van een zonnecel een negatieve spanning en de andere laag een positieve
                    spanning. Als de twee lagen met elkaar verbonden worden, gaat er een elektrische stroom lopen.
                </p>

                <h2>Wanneer werken zonnepanelen?</h2>
                <p>
                    Zonnepanelen zouden beter lichtpanelen kunnen heten. Ook als het bewolkt is of in de winter zetten
                    zonnepanelen het licht om in electriciteit.
                </p>
                <p>
                    Zonnepanelen worden meestal op het dak geplaatst. Dat kan een schuin of plat dak zijn. Bij
                    installatie van de zonnepanelen op een schuin dak is het belangrijk dat de hellingshoek van het dak
                    goed is, zodat het zonnepaneel zoveel mogelijk licht opvangt. Op een plat dak kun je zelf de
                    hellingshoek bepalen doordat de zonnepanelen in een bak (console) worden gezet. Zonnepanelen kun je
                    ook op de gevel monteren. Houd dan rekening met een 25% lagere opbrengst.
                </p>

                <h2>Handig om te weten</h2>
                <p>
                    Voor het plaatsen van zonnepanelen heb je geen vergunning nodig. Uitzondering zijn woningen die als
                    monument zijn aangemerkt en woningen die deel uitmaken van een beschermd stads- of dorpsgezicht.
                    Twijfel je? Informeer bij je gemeente. Of controleer of je een vergunning nodig hebt op het
                    omgevingsloket van de rijksoverheid.
                </p>

            </div>

            <CallToAction/>
        </React.Fragment>
    );
}

export default Producten;
