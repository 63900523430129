import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

function ThuisbatterijHoeWerktHet() {
    return (
        <React.Fragment>
            <Helmet
                title="Thuisbatterij: Hoe werkt het?"
                description="Optimaliseer je energieverbruik met een thuisbatterij van Smith Solar. Maximale zelfvoorziening en slimme energieopslag. Vraag direct jouw offerte aan!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Thuisbatterij</h1>
                    <p>Hoe werkt het?</p>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">

                <div className="article-open">
                    <p>
                        In het tijdperk van duurzame energie kiezen steeds meer huishoudens voor een thuisbatterij om
                        hun energievoorziening te optimaliseren. Bij Smith Solar begrijpen we het belang van innovatieve
                        oplossingen voor energiebeheer. Een thuisbatterij is meer dan een toevoeging; het is een
                        essentieel onderdeel van een moderne, zelfvoorzienende woning. Ontdek hoe een thuisbatterij
                        werkt en hoe je jouw eigen verbruik van zonnepanelen kunt maximaliseren.
                    </p>
                </div>

                <h2>
                    Hoe werkt een thuisbatterij?
                </h2>
                <p>
                    Een thuisbatterij slaat overtollige energie op die jouw zonnepanelen genereren of trekt energie van
                    het net wanneer dat voordeliger is. Deze flexibiliteit betekent dat je altijd de meest economische
                    en duurzame keuze kunt maken voor jouw energievoorziening.
                </p>

                <h2>
                    Laad- en ontlaadmechanismen
                </h2>
                <p>
                    Een thuisbatterij kan op diverse manieren aangestuurd worden, al dan niet gelijktijdig, om aan jouw
                    specifieke energiebehoeften te voldoen. Zo kun je jouw thuisbatterij opslag optimaal benutten,
                    ongeacht de omstandigheden.
                </p>

                <div className="card-rounded mb-3">

                    <h3>
                        Maximaliseer jouw eigen energieverbruik
                    </h3>
                    <p>
                        Met de juiste instellingen laadt jouw thuisbatterij op met overtollige zonne-energie en springt
                        bij wanneer jouw verbruik de productie overstijgt. Zo gebruik je zoveel mogelijk jouw eigen
                        energie, wat resulteert in een lagere energierekening en minder afhankelijkheid van het
                        energienet.
                    </p>

                </div>

                <div className="card-rounded mb-3">

                    <h3>
                        Slimme sturing op de EPEX markt
                    </h3>
                    <p>
                        Door jouw thuisbatterij slim aan te sturen op basis van het dynamisch energietarief van de EPEX
                        markt, kun je profiteren van lagere energiekosten door op de juiste momenten energie op te slaan
                        of terug te leveren aan het net.
                    </p>

                </div>

                <div className="card-rounded mb-3">

                    <h3>
                        Anticiperen op de onbalansmarkt
                    </h3>
                    <p>
                        De onbalansmarkt biedt kansen om een hoog rendement te realiseren dankzij fluctuerende prijzen.
                        Door actief deel te nemen, help je niet alleen jouw portemonnee, maar draag je ook bij aan het
                        verminderen van netcongestie, oftewel file op het elektriciteitsnet.
                    </p>

                </div>

                <div className="card-rounded mb-3">

                    <h3>
                        Peakshaving: een slimme oplossing voor jouw energiebehoefte
                    </h3>
                    <p>
                        Peakshaving helpt bij het afstemmen van vraag en aanbod van energie binnen jouw huishouden, wat
                        bijzonder nuttig is als jouw hoofdaansluiting niet voldoet aan jouw energiebehoefte. Een
                        thuisbatterij met peakshaving-functie kan kosteneffectiever zijn dan het verzwaren van jouw
                        aansluiting.
                    </p>

                </div>

                <h2 className="mt-5">
                    Ons eigen Energie Management Systeem
                </h2>
                <p>
                    Al deze functionaliteiten zijn te beheren met ons eigen{" "}
                    <Link to="/ems/">Energie Management Systeem (EMS)</Link>{" "}
                    van GridSense, waarmee je volledige controle hebt over jouw energieverbruik en -opslag. Ben je
                    geïnteresseerd in het optimaliseren van jouw energieverbruik en het vergroenen van jouw
                    energievoorziening?{" "}
                    <Link to="/contact/">Neem contact met ons op</Link>{" "}
                    of{" "}
                    <Link to="/offerte/">vraag een offerte aan</Link>{" "}
                    voor meer informatie over de mogelijkheden van een thuisbatterij bij Smith Solar.
                </p>

            </div>

            <CallToAction/>

        </React.Fragment>
    )
}

export default React.memo(ThuisbatterijHoeWerktHet);
