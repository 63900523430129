import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/afore.svg";
import aforeNewInvertersManual from "../../files/manuals/afore-wifi-solarman-inverters-manual.pdf";

function AforeSolarman() {
    return (
        <Monitoring
            brandName="Afore Solarman"
            logoUrl={ logo }
            desktopUrl="https://home.solarman.cn/login.html?lang=2"
            iosUrl="https://apps.apple.com/nl/app/solarman-the-great-owners-app/id1269498647"
            androidUrl="https://play.google.com/store/apps/details?id=com.igen.rrgf"
            manual={ aforeNewInvertersManual }
        />
    )
}

export default AforeSolarman;
