import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {
    CSSTransition
} from "react-transition-group";
import {
    Button
} from "react-bootstrap";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { clarity } from "clarity-js";

function permissionToGAConsentString(value) {
    return value ? "granted" : "denied";
}

export function permissionsToGAConsents(permissions) {
    // https://support.google.com/tagmanager/answer/10718549
    return {
        ad_storage: permissionToGAConsentString(permissions.marketing),
        ad_user_data: permissionToGAConsentString(permissions.marketing),
        ad_personalization: permissionToGAConsentString(permissions.marketing),
        analytics_storage: permissionToGAConsentString(permissions.analytics),

        functionality_storage: permissionToGAConsentString(permissions.necessary),
        personalization_storage: permissionToGAConsentString(permissions.preferences),
        security_storage: permissionToGAConsentString(permissions.necessary),
    }
}

function applyPermissions(permissions) {
    console.log("Consents", permissions);
    localStorage.setItem("cookie-consent", JSON.stringify(permissions));

    // Google Analytics
    const gaConsents = permissionsToGAConsents(permissions);
    ReactGA.gtag("consent", "update", gaConsents);

    // Facebook Pixel
    if(permissions.marketing) {
        ReactPixel.grantConsent();
    }

    // Microsoft Clarity
    if(permissions.analytics) {
        clarity.consent();
    }
}

function ConsentCheckbox({ id, name, checked, onChange, disabled }) {
    return (
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                className="custom-control-input"
                id={ id }
                checked={ checked }
                onChange={ onChange }
                disabled={ disabled }
            />
            <label className="custom-control-label" htmlFor={ id }>
                { name }
            </label>
        </div>
    );
}

function CookieConsentBanner() {
    const [show, setShow] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [permissions, setPermissions] = useState({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: false
    });
    const nodeRef = useRef(null);

    const onChecked = useCallback((event) => {
        setPermissions((oldPermissions) => {
            return { ...oldPermissions, [event.target.id]: event.target.checked }
        });
    }, []);

    useEffect(() => {
        const consent = localStorage.getItem("cookie-consent");
        if(consent) {
            setPermissions(JSON.parse(consent));
            applyPermissions(JSON.parse(consent));
        } else {
            setShow(true);
        }
    }, []);

    const onSet = useCallback(() => {
        setShow(false);
        applyPermissions(permissions);
    }, [permissions]);
    const onAcceptAll = useCallback(() => {
        setShow(false);
        applyPermissions({
            necessary: true,
            analytics: true,
            preferences: true,
            marketing: true
        });
    }, []);

    return (
        <div>
            <CSSTransition
                nodeRef={ nodeRef }
                in={ show }
                timeout={ 300 }
                classNames="cookie-consent-banner-animation"
                unmountOnExit
            >
                <div ref={ nodeRef } className="cookie-consent-banner-container">
                    <div className="cookie-consent-banner-card">
                        <p className="cookie-consent-banner-title">
                            <i className="fa-solid fa-cookie-bite text-primary mr-2"/>
                            Cookies
                        </p>
                        <p>Wij gebruiken cookies om je ervaring te verbeteren.</p>
                        { showOptions && (
                            <div className="cookie-checkbox-row">
                                <ConsentCheckbox
                                    id="necessary"
                                    name="Noodzakelijke"
                                    checked={ permissions.necessary }
                                    onChange={ onChecked }
                                    disabled
                                />
                                <ConsentCheckbox
                                    id="analytics"
                                    name="Analytische"
                                    checked={ permissions.analytics }
                                    onChange={ onChecked }
                                />
                                <ConsentCheckbox
                                    id="preferences"
                                    name="Voorkeuren"
                                    checked={ permissions.preferences }
                                    onChange={ onChecked }
                                />
                                <ConsentCheckbox
                                    id="marketing"
                                    name="Marketing"
                                    checked={ permissions.marketing }
                                    onChange={ onChecked }
                                />
                            </div>
                        )}
                        <div className="d-flex flex-row mt-2">
                            <div className="flex-grow-1">
                                { showOptions ? (
                                    <Button
                                        variant="dark"
                                        onClick={ onSet }
                                        className="mr-1"
                                    >
                                        Opslaan
                                    </Button>
                                ) : (
                                    <Button
                                        variant="dark"
                                        onClick={ () => setShowOptions(true) }
                                        className="mr-1"
                                    >
                                        Wijzigen
                                    </Button>
                                )}
                            </div>
                            <Button
                                variant="success"
                                onClick={ onAcceptAll }
                                className="ml-1"
                            >
                                Alles accepteren
                            </Button>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default React.memo(CookieConsentBanner);
