import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";

import ThemeContext from "../context/ThemeContext";

import bg from "../img/home/full-black-friday-calltoaction.jpg";

function BlackFridayCallToAction() {
    const theme = useContext(ThemeContext);
    if(!theme.blackFriday) {
        return null;
    }

    return (
        <Link
            to="/offerte/"
            className="black-friday-calltoaction mb-3"
        >
            <img src={ bg } alt="Black Friday"/>
            <div
                className="bf-body"
            >
                5% korting op
                <br/>
                alle installaties
            </div>
        </Link>
    )
}

export default React.memo(BlackFridayCallToAction);
