import React from "react";
import {
    Link
} from "react-router-dom";

function SupportLink({ to, icon, title }) {
    return (
        <Link to={ to } style={{ textDecoration: "none" }}>
            <div className="card-rounded d-flex flex-column align-items-center">
                <i className={ icon } style={ { fontSize: "3rem" } }/>
                <div style={ { fontSize: "1.5rem" } }>
                    { title }
                </div>
            </div>
        </Link>
    )
}

export default React.memo(SupportLink);
