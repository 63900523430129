import React from "react";
import Helmet from "../../Helmet";

import appStoreBadge from "./../../../img/appstore.svg";
import googlePlayBadge from "./../../../img/googleplay.svg";

function Monitoring({ brandName, logoUrl, desktopUrl, iosUrl, androidUrl, manual = null, alert }) {
    return (
        <React.Fragment>
            <Helmet
                title={ brandName + " Monitoring" }
                description={ "Welke monitoring app heeft u nodig voor " + brandName + "? Dat wordt hier uitgelegd. Hoe verbind ik mijn " + brandName + " omvormer met wifi?" }
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>{ brandName }</h1>
                    <p>Monitoring</p>
                </div>
            </div>

            <div className="container">

                { alert }

                { logoUrl && (
                    <div className="d-none d-sm-block float-right">
                        <img src={ logoUrl } style={ { width: "100%", maxHeight: "75px" } } alt={ brandName }/>
                    </div>
                ) }

                <h2>Monitoring</h2>

                <p>Alle { brandName } omvormers die wij leveren hebben standaard Wifi. Dat zorgt ervoor dat u via uw computer of
                    smartphone de opbrengsten van uw zonnepanelen kunt bekijken, en dit overal ter wereld.</p>

                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-desktop fa-fw text-primary" style={{ fontSize: "60px" }}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Computer</h4>
                                <p className="card-text">
                                    Voor het bekijken van uw opbrengsten op de computer, gaat u naar de monitoring
                                    website van { brandName } door op de knop te klikken.
                                </p>
                                <a href={ desktopUrl } className="btn btn-secondary" target="_blank" rel="noopener noreferrer">
                                    Monitoring Paneel{" "}
                                    <small><i className="fas fa-external-link" aria-hidden="true"/></small>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-mobile-alt fa-fw text-primary" style={{ fontSize: "60px" }}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Smartphone</h4>
                                <p className="card-text">
                                    Indien u uw opbrengsten via uw smartphone wilt bekijken, kunt u een app hiervoor
                                    downloaden. Er zijn apps voor iPhone en Android toestellen beschikbaar. Klik op de
                                    knoppen hieronder voor uw smartphone variant om de app te downloaden.
                                </p>
                                <div className="d-block">
                                    <a href={ iosUrl } target="_blank" rel="noreferrer noopener" className="mr-2">
                                        <img src={ appStoreBadge } style={{ maxHeight: "40px", maxWidth: "120px" }} alt="Download in de App Store"/>
                                    </a>
                                    <a href={ androidUrl } target="_blank" rel="noreferrer noopener">
                                        <img src={ googlePlayBadge } style={{ maxHeight: "40px", maxWidth: "130px" }} alt="Ontdek het op Google Play"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                { manual !== null && (
                    <React.Fragment>
                        <h2>Wifi opnieuw instellen</h2>

                        <p>
                            Het kan voorkomen dat er geen opbrengst meer te zien is in het monitoring platform. Meestal
                            betekent dit dat wifi opnieuw ingesteld moet worden op de omvormer. Oorzaken hiervan kunnen
                            zijn dat u bijvoorbeeld een nieuwe router heeft. Hieronder vind u een handleiding om wifi
                            opnieuw in te stellen op uw omvormer.
                        </p>

                        <a href={ manual } className="btn btn-secondary mb-3" target="_blank" rel="noopener noreferrer">
                            Handleiding wifi instellen{" "}
                            <small><i className="fas fa-external-link" aria-hidden="true"/></small>
                        </a>
                    </React.Fragment>
                )}

            </div>
        </React.Fragment>
    )
}

export default Monitoring;
