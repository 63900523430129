import React from "react";

import NavbarLink from "../NavbarLink";
import SubNavbar from "../SubNavbar";

function LaadpaalSubNavbar() {
    return (
        <SubNavbar>
            <NavbarLink to="/laadpalen/" exact>
                <i className="fa-solid fa-house fa-fw mr-2" />
                Overzicht
            </NavbarLink>
            <NavbarLink to="/laadpalen/solaredge/" exact>
                <i className="fa-solid fa-charging-station fa-fw mr-2" />
                SolarEdge
            </NavbarLink>
            <NavbarLink to="/laadpalen/autel-ac-wallbox/" exact>
                <i className="fa-solid fa-charging-station fa-fw mr-2" />
                Autel AC Wallbox
            </NavbarLink>
        </SubNavbar>
    );
}

export default React.memo(LaadpaalSubNavbar);
