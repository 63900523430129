import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import CallToAction from "../../components/CallToAction";

import gridSenseLogoWhite from "../../img/gridsense/gridsense-logo-white.svg";
import batteryImage from "../../img/battery/solaredge-home-battery-400V.png";

function BTWTerugvraagBattery() {
    return (
        <React.Fragment>
            <Helmet
                title="BTW Terugvraag - Thuisbatterij"
                description="Zonnepanelen en thuisbatterijen komen in aanmerking voor btw-teruggave. Smith Solar B.V. helpt je hierbij! Neem nu contact op voor het terugvragen van btw."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>BTW Terugvraag</h1>
                    <p>Vraag de btw over uw thuisbatterij terug!</p>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">
                <div className="bigger-paragraphs mb-4">
                    <p>
                        Overweeg je om je woning te voorzien van een thuisbatterij om je energieverbruik te
                        optimaliseren? Dan is het interessant om te weten dat je de btw op de aanschaf van een
                        thuisbatterij voor zonnepanelen kunt terugvragen. Voor het terugvorderen van de btw kunt u de
                        experts van Smith Solar B.V. inschakelen. Wij leveren deze service aan onze klanten en regelen
                        dit van A tot Z.
                    </p>
                </div>

                <h2>Btw terugvorderen op thuisbatterij</h2>
                <p>
                    Het terugvragen van btw bij thuisbatterijen is een belangrijk aspect voor wie investeert in duurzame
                    energieoplossingen. Net zoals bij zonnepanelen, kan de btw op de aanschaf van een thuisbatterij
                    worden teruggevraagd. Dankzij deze teruggave worden de totale kosten van de installatie van een
                    thuisbatterij aanzienlijk verminderd, waardoor het een aantrekkelijke investering is voor veel
                    huishoudens.
                </p>

                <h2>De voorwaarden voor btw-teruggave</h2>
                <p>
                    Voor het terugvragen van btw op thuisbatterijen gelden er een aantal voorwaarden. Door aan
                    onderstaande criteria te voldoen, kom je in aanmerking voor het terugvorderen van het btw-tarief op
                    je thuisbatterij:
                </p>
            </div>

            <div className="container my-5">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="card-rounded">
                            <img
                                src={ batteryImage }
                                className="img-fluid"
                                alt="Thuisbatterij"
                            />
                        </div>
                    </div>
                    <div className="col-md-7">

                        <h3>Dynamisch contract afsluiten</h3>
                        <p>
                            Het afsluiten van een dynamisch contract biedt de vrijheid om flexibel in te spelen op de
                            veranderende behoeften en omstandigheden.
                        </p>

                        <h3 className="mt-4">Beschikken over een slimme sturing</h3>
                        <p>
                            Met dit systeem wordt de thuisbatterij op een slimme manier aangestuurd en efficiënt
                            ingezet.
                        </p>

                    </div>
                </div>
            </div>

            <div className="py-5 background-gridsense">
                <div className="container mt-4 text-white">

                    <h2 className="mb-4 text-center">
                        Slim sturen met energie management systeem GridSense
                    </h2>

                    <div className="d-flex justify-content-center px-4 my-4">
                        <img
                            src={ gridSenseLogoWhite }
                            className="img-fluid"
                            alt="GridSense"
                            style={ { maxWidth: "400px" } }
                        />
                    </div>

                    <div className="row">
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={ { fontSize: "1.3rem" } }>
                                Zonnestroom slim inzetten
                            </h3>
                            <p>
                                GridSense is een <Link to="/ems/">energie management systeem</Link> dat opgeslagen
                                zonnestroom in je thuisbatterij op een slimme manier inzet.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={ { fontSize: "1.3rem" } }>
                                Thuisbatterij aansturen op basis van dynamische energieprijzen
                            </h3>
                            <p>
                                Het systeem stuurt je thuisbatterij aan op basis van de energieprijzen binnen je
                                dynamische
                                energiecontract.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={ { fontSize: "1.3rem" } }>
                                Thuisbatterij opladen met eigen zonnestroom
                            </h3>
                            <p>
                                Tijdens de laagste prijzen van de dag laadt GridSense je thuisbatterij op met eigen
                                opgeslagen zonnestroom.
                            </p>
                        </div>
                        <div className="col-xl-3 col-md-6 my-3">
                            <h3 style={ { fontSize: "1.3rem" } }>
                                Heel het jaar optimaal gebruikmaken van thuisbatterij
                            </h3>
                            <p>
                                Dankzij deze slimme sturing maak je heel het jaar efficiënt gebruik van je
                                thuisbatterij,
                                ook in de winter.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container container-sm mt-5">
                <h2>Hoe btw terugvragen?</h2>
                <p>
                    Het hele btw-bedrag dat in rekening is gebracht voor de aanschaf en installatie van een
                    thuisbatterij kun je in aftrek brengen. Dit doen we bij Smith Solar als volgt:
                </p>
                <ul>
                    <li>
                        We melden je aan als btw-ondernemer en vragen de btw voor je terug bij de Belastingdienst.
                    </li>
                    <li>
                        We vragen een ontheffing van de administratieve verplichting (btw-afdracht) aan.
                    </li>
                    <li>
                        Voor het terugvragen van btw bedragen de kosten € 100,- exclusief btw. De btw hiervan kan ook teruggevraagd worden.
                    </li>
                </ul>

                <h2>Contact</h2>
                <p>
                    Wil je meer weten over het terugvragen van btw voor thuisbatterijen of over ons energie management
                    systeem? Bij Smith Solar ben je hiervoor aan het juiste adres. Wij vertellen je graag meer over de
                    terugvordering van btw en hoe we jou daarbij kunnen helpen. Neem vandaag
                    nog <Link to="/contact/">contact</Link> op met ons en laat je adviseren door onze experts.
                </p>

            </div>

            <CallToAction/>

        </React.Fragment>
    );
}

export default React.memo(BTWTerugvraagBattery);
