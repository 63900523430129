import React from "react";
import PropTypes from "prop-types";

export const StepsList = ({ children }) => {
    return (
        <div>
            <ol className="step-container">{children}</ol>
        </div>
    );
};

export const Step = ({ title, children, stepTitle }) => {
    return (
        <li className="step">
            <div className="circle">
                <span className="circle-text">{stepTitle}</span>
            </div>
            <div className="step-content">
                <h3>{title}</h3>
                {children}
            </div>
        </li>
    );
};
