import React from "react";
import Helmet from "../../components/Helmet";

import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

import polyOfMonoCells from "../../img/zonnepanelen/poly-and-mono-solar-cells.jpg";

function ZonnepanelenMonoOfPoly() {
    return (
        <React.Fragment>
            <Helmet
                title="Mono of Poly zonnepanelen"
                description="Wat is het verschil tussen Mono en Poly zonnepanelen? Hier wordt het duidelijk gemaakt."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <p>Mono of Poly zonnepanelen</p>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container">

                <div className="figure float-right" style={{ maxWidth: "400px", margin: "20px", marginTop: 0 }}>
                    <img src={ polyOfMonoCells } className="figure-img img-fluid rounded"
                         alt="Links een Mono-crystalline cell en rechts een Poly-crystalline cell"/>
                    <div className="figure-caption">
                        Links een Mono-crystalline cell en rechts een Poly-crystalline cell
                    </div>
                </div>

                <h2>Het verschil tussen Mono en Poly</h2>

                <p>
                    Poly-kristallijn silicium en mono-kristallijn silicium worden allebei uit dezelfde grondstof
                    gemaakt, namelijk siliciumzand. Bij het stolproces van de poly-kristallijne silicium van vloeibare
                    naar vaste vorm, laat men het silicium gewoon stollen zonder extra handelingen. De kristallen liggen
                    dan in kriskras richting. Bij mono-kristallijn silicium gaat men de polarisatie van de kristallen
                    beïnvloeden tijdens het stollen, zodat deze alle in dezelfde richting komen te liggen. Bij directe
                    zonlicht instraling zal een mono-kristallijne cel iets meer stroom opwekken dan een
                    poly-kristallijne cel van dezelfde oppervlakte. Met andere woorden, een mono-kristallijne cel neemt
                    minder oppervlakte in dan een poly-kristallijne. Uiteraard zit er wel wat verschil in het
                    prijskaartje, omdat de extra handeling altijd iets kost.
                </p>

                <h2>Opbrengst</h2>

                <p>
                    Anderzijds is er in opbrengst per gebruikte WattPiek zo goed als geen verschil. Men zal op dezelfde
                    oppervlakte panelen, bij mono-kristallijne types meer vermogen kunnen plaatsen dan bij
                    poly-kristallijne. Anders gezegd, een installatie (van hetzelfde vermogen) met mono-kristallijne
                    panelen zal “compacter” zijn dan met poly-kristallijne cellen. Dus wie een klein dak heeft, kan zeer
                    gebaat zijn met mono-kristallijne cellen. Voor wie dan weer over voldoende dakruimte beschikt, maakt
                    het niet uit welk type paneel er op komt. Voor elke 1000 Wp (= 1kWp) levert een installatie ongeveer
                    850 kWh. Men noemt dit de verhoudingsfactor vermogen/opbrengst, soms wel eens “draai-uren” genoemd.
                </p>

                <h2>Kwaliteit van de zonnepanelen</h2>

                <p>
                    De kwaliteit en de garantie is voor zowel mono- als voor poly- identiek. De panelen hebben meestal
                    de volgende garanties:
                </p>
                <ul>
                    <li>10 jaar productgarantie</li>
                    <li>10 jaar 90 procent vermogensgarantie</li>
                    <li>25 jaar 80 procent vermogensgarantie</li>
                </ul>

                <h2>Conclusie</h2>

                <p>
                    Buiten wat detailverschillen is het enige belangrijke verschil dus de oppervlakte die de panelen
                    innemen op een dak. Mono neemt dus minder plaats in dan poly. De opbrengstverhouding is nagenoeg
                    gelijk.
                </p>

            </div>

            <CallToAction/>
        </React.Fragment>
    );
}

export default ZonnepanelenMonoOfPoly;
