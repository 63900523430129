import React from "react";

import Helmet from "../components/Helmet";

import manualEnergieleveren from "../files/manuals/energieleveren-manual.pdf";

function Energieleveren() {
    return (
        <React.Fragment>
            <Helmet title="Energieleveren.nl"/>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Energieleveren.nl</h1>
                </div>
            </div>

            <div className="container" style={{ maxWidth: "800px" }}>

                <p style={{ fontSize: "1.2rem" }}>
                    Nadat u zonnepanelen heeft laten plaatsen, dan is het wettelijk verplicht om uw installatie aan te
                    melden bij Energieleveren.nl
                </p>

                <p>
                    Energieleveren.nl is een initiatief van de regionale netbeheerders in Nederland. Het is namelijk
                    verplicht om uw zonnepanelen installatie aan te melden bij de netbeheerder. De netbeheerder wil
                    weten wat voor energieopwekkers er op het net zijn aangesloten om op die manier het net zo optimaal
                    mogelijk te beheren. Energieleveren.nl communiceert de ingevulde gegevens met de correcte
                    netbeheerder.
                </p>
                <a
                    href="https://energieleveren.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary mb-3"
                >
                    Aanmelden bij Energieleveren.nl
                </a>

                <h3>
                   Hoe meld ik mijn zonnepaneleninstallatie aan?
                </h3>
                <p>
                    Aanmelden bij Energieleveren.nl is binnen 5 minuten geregeld. Hieronder vind u een handleiding
                    waarin wordt uitgelegd hoe u zich aanmeld.
                </p>
                <a
                    href={ manualEnergieleveren }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-secondary mb-3"
                >
                    Energieleveren.nl handleiding
                </a>

                <h3>
                    Wat als er later iets wijzigt aan uw installatie?
                </h3>
                <p>
                    Als er op een later moment iets veranderd aan uw zonnepaneleninstallatie, dan kunt u dit via
                    Energieleveren.nl eenvoudig wijzigen. Bij de eerste aanmelding maakt u namelijk een account aan. Als
                    u met dit account weer inlogd, dan kunt u de ingevulde gegevens wijzigen.
                </p>

            </div>
        </React.Fragment>
    )
}

export default React.memo(Energieleveren);
