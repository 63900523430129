import React, {
    useEffect,
    useState
} from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import CallToAction from "../../components/CallToAction";
import useWindowSize from "../../hooks/WindowSize";

import banner from "../../img/solar-banner.jpg";

function Mobile() {
    const [bannerHeight, setBannerHeight] = useState(0);
    const windowSize = useWindowSize();

    useEffect(() => {
        if(window.innerWidth <= 575) {
            setBannerHeight(window.innerHeight - 80);
        } else {
            setBannerHeight(window.innerHeight - 152);
        }
    }, [windowSize.orientation]);

    return (
        <React.Fragment>
            <Helmet/>

            <div style={{
                backgroundImage: "url('" + banner + "')",
                backgroundSize: "cover",
                backgroundPosition: "center"
            }}>
                <div
                    className="container-fluid w-100"
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.4)"
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-center align-items-center w-100 py-3 py-md-5"
                        style={{
                            minHeight: bannerHeight + "px",
                        }}
                    >
                        <div
                            className="d-block py-5 px-4"
                            style={{
                                borderRadius: "10px",
                                minHeight: "450px",
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                maxWidth: "500px"
                            }}
                        >
                            <div style={{ transform: "rotate(-7deg)", textAlign: "center", marginBottom: "40px" }}>
                                <p className="slogan " style={{ fontSize: "40px" }}>Besparen op uw energierekening?</p>
                            </div>
                            <div className="text-center mb-5" style={{ fontSize: "25px", fontWeight: "500" }}>
                                <p>Vraag binnen 2 minuten <br className="d-md-none"/>een gratis offerte aan voor zonnepanelen!</p>
                            </div>
                            <div className="text-center">
                                <Link to="/offerte/" className="btn btn-primary btn-lg px-4">
                                    Offerte aanvragen
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container pt-5">
                <h2>Waarom zonnepanelen?</h2>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-clock fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">4-6 jaar terugverdientijd</h4>
                                <p className="card-text">
                                    Onze prijzen zijn scherp. Daarom is het mogelijk om uw volledige zonnepanelen
                                    installatie, inclusief materiaal en montage, terug te verdienen binnen 4 tot 6 jaar!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-home fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">0% btw over zonnepanelen</h4>
                                <p className="card-text">
                                    In 2023 hoeft over zonnepanelen voor woonhuizen geen btw betaalt te worden. Ontdek
                                    via onderstaande knop hoe dit precies zit.
                                </p>
                                <Link to="/btw/" className="btn btn-primary">Meer informatie</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-map-marker-alt fa-fw text-primary" style={{fontSize: "60px"}}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Opname op locatie</h4>
                                <p className="card-text">
                                    Wij maken een afspraak met u na uw offerte aanvraag om met u de offerte door te
                                    nemen. Ook kijken we nog extra goed naar uw dak zodat we nog aanpassingen aan de
                                    offerte kunnen maken. Dit zorgt voor een probleemloze montage.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-1half col-xl-1 text-center">
                                <i className="fas fa-star fa-fw text-primary" style={{ fontSize: "60px" }}/>
                                <br/><br/>
                            </div>
                            <div className="col-lg-10half col-xl-11">
                                <h4 className="card-title">Lees want klanten van ons zeggen</h4>
                                <p className="card-text">
                                    Klanten die u voor zijn gegaan, beoordelen ons met een <b className="b-solar">8.9</b>. Kijk wat zij van ons zeggen.
                                </p>
                                <a href="https://referenties.smithsolar.nl/reviews" className="btn btn-primary">Recensies</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CallToAction title={
                <React.Fragment>
                    <small className="text-muted">
                        Bent u er klaar voor?
                    </small>
                    <br/>
                    Vraag vandaag nog een offerte aan!
                </React.Fragment>
            }/>
        </React.Fragment>
    )
}

export default Mobile;
