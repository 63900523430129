import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/apsystems.svg";

function APsystems() {
    return (
        <Monitoring
            brandName="APsystems"
            logoUrl={ logo }
            desktopUrl="https://apsystemsema.com/ema/index.action"
            iosUrl="https://apps.apple.com/nl/app/ema-app/id984843541"
            androidUrl="https://play.google.com/store/apps/details?id=com.apsemaappforandroid&hl=nl&gl=NL"
        />
    )
}

export default React.memo(APsystems);
