import React from "react";
import { Link } from "react-router-dom";

import CallToAction from "../components/CallToAction";
import Helmet from "../components/Helmet";
import JumbotronCallToAction from "../components/JumbotronCallToAction";

import ProductenZonnepanelenCarousel from "./../components/pages/producten/ProductenZonnepanelenCarousel";
import ProductenOmvormersCarousel from "../components/pages/producten/ProductenOmvormersCarousel";
import ProductenBevestigingsmateriaalCarousel from "../components/pages/producten/ProductenBevestigingsmateriaalCarousel";

function Producten() {
    return (
        <React.Fragment>
            <Helmet title="Producten" />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Producten</h1>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <h2>
                    <small>
                        <span className="icon-solar-panel" />
                    </small>{" "}
                    Zonnepanelen
                </h2>
                <p>
                    Ontdek de kracht van zonne-energie met Ulica Solar! Bij Smith Solar B.V. gaan we voor niets minder dan topkwaliteit en dat is
                    precies wat Ulica Solar zonnepanelen bieden. Deze Tier 1 zonnepanelen stralen innovatie en betrouwbaarheid uit, waardoor ze een
                    favoriet zijn bij onze klanten.
                </p>
                <p>
                    Waarom kiezen voor Ulica Solar zonnepanelen? Ten eerste, ze zijn Tier 1-geclassificeerd, wat de hoogste normen van kwaliteit en
                    efficiëntie bevestigt. Dit betekent dat ze duurzaam zijn en consistente energieopbrengsten leveren. De opbrengstgarantie is
                    indrukwekkend: na 10 jaar behouden ze 90% efficiëntie en na 25 jaar nog steeds 80%. Dit betekent{" "}
                    <b>jarenlang zorgeloos profiteren van groene energie</b>.
                </p>
                <p>
                    Daarnaast biedt Ulica Solar een royale <b>fabrieksgarantie van 12 jaar</b>, waardoor je beschermd bent tegen fabricagefouten en
                    onverwachte problemen. Wij werken al sinds 2013 samen met Ulica Solar waardoor we toegang hebben tot de nieuwste technologieën en
                    innovaties op het gebied van zonne-energie en jou als klant altijd de beste oplossing kunnen bieden.
                </p>
                <p>
                    Wil je profiteren van de voordelen van Ulica Solar zonnepanelen voor jouw huis of bedrijf? Vraag vandaag nog een offerte aan bij
                    Smith Solar B.V. en draag bij aan een groenere toekomst terwijl je bespaart op je energierekening.
                </p>
                <Link to="/offerte/" className="btn btn-primary">
                    Offerte aanvragen
                </Link>
            </div>

            <br />

            <ProductenZonnepanelenCarousel />

            <br />
            <br />

            <div className="container container-sm">
                <h2>
                    <small>
                        <span className="icon-solar-inverter" />
                    </small>{" "}
                    Omvormers
                </h2>
                <p>
                    Een <b>omvormer</b> is een essentieel onderdeel van elk zonne-energiesysteem. Omvormers spelen een cruciale rol in het omzetten
                    van de opgevangen zonne-energie in bruikbare stroom voor je huis of bedrijf. Daarom vind je bij Smith Solar een groot aanbod
                    omvormers van topmerken als SolarEdge, Enphase, APsystems en Afore om ervoor te zorgen dat jouw zonne-energiesysteem op zijn best
                    presteert.
                </p>
                <p>
                    Voor degenen die op zoek zijn naar <b>topprestaties</b> en monitoring mogelijkheden, is de <b>SolarEdge</b> omvormer een
                    uitstekende keuze. Deze omvormers optimaliseren de opbrengst van elk afzonderlijk zonnepaneel, wat resulteert in maximale
                    efficiëntie.
                </p>
                <p>
                    <b>Enphase</b> micro omvormers zijn ideaal voor wie het beste uit elk zonnepaneel wil halen. Deze innovatieve micro-omvormers
                    zorgen voor een betere betrouwbaarheid en opbrengst, zelfs onder variabele omstandigheden.
                </p>
                <p>
                    Voor wie op zoek is naar kostenefficiënte micro omvormers, bieden we de <b>APsystems</b> micro omvormer. Deze omvormers leveren
                    consistente prestaties en betrouwbaarheid.
                </p>
                <p>
                    Een Afore omvormer staat bekend om zijn hoogwaardige kwaliteit en betrouwbare prestaties, en biedt een uitstekende
                    prijs-kwaliteitverhouding. Of het nu gaat om een kleine residentiële installatie of een grootschalig commercieel
                    zonne-energieproject, Afore omvormers zijn flexibel, compatibel met verschillende batterijen en bieden real-time monitoring via de
                    Solarman app.
                </p>
                <p>
                    Of je nu kiest voor SolarEdge, Enphase, APsystems of Afore omvormer, Smith Solar zorgt voor de best mogelijke omvormer voor jouw
                    zonne-energiesysteem. Benieuwd naar de kosten en mogelijkheden? Vraag vandaag nog een offerte aan!
                </p>
                <Link to="/offerte/" className="btn btn-primary">
                    Offerte aanvragen
                </Link>
            </div>

            <br />

            <ProductenOmvormersCarousel />

            <br />
            <br />

            <div className="container container-sm">
                <h2>
                    <small>
                        <i className="fas fa-screwdriver" />
                    </small>{" "}
                    Bevestigingsmateriaal
                </h2>
                <p>
                    Het juiste bevestigingsmateriaal is cruciaal voor een goede werking van jouw zonne-energiesysteem. Bij Smith Solar hebben we een
                    selectie bevestigingsmateriaal van topkwaliteit om ervoor te zorgen dat jouw zonnepanelen veilig en efficiënt worden
                    geïnstalleerd. Of je nu een plat dak of een schuin dak hebt, we hebben de perfecte oplossingen voor jou.
                </p>
                <p>
                    <b>Flat Fix Fusion</b> is een geavanceerd en lichtgewicht montagesysteem voor zonnepanelen met geïntegreerde winddeflectoren en
                    modulaire flexibiliteit, waardoor het snel geïnstalleerd kan worden. Het systeem is uitermate geschikt als bevestigingsmateriaal
                    zonnepanelen voor een plat dak en heeft een geïntegreerd kabelmanagement systeem. Flat Fix Fusion is verkrijgbaar in een
                    aantrekkelijke zwarte uitvoering en wordt geleverd met een indrukwekkende garantie van 20 jaar.
                </p>
                <p>
                    Ben je op zoek naar bevestigingsmateriaal voor zonnepanelen voor een schuin dak, dan is <b>Valkpitched</b> een uitstekende keuze.
                    Dit systeem is speciaal ontwikkeld om zonnepanelen op schuine daken te bevestigen en biedt duurzame prestaties en betrouwbaarheid.
                    Met Valkpitched ben je verzekerd van een veilige installatie die jarenlang meegaat.
                </p>
                <p>
                    Ons bevestigingsmateriaal voor zonnepanelen is zorgvuldig geselecteerd om te voldoen aan de hoogste normen van kwaliteit en
                    duurzaamheid. Of je nu zonnepanelen op een plat dak of een schuin dak wilt installeren, Smith Solar heeft het juiste
                    bevestigingsmateriaal om ervoor te zorgen dat jouw zonne-energiesysteem optimaal presteert en lang meegaat.
                </p>
                <p>
                    Kies voor de beste bevestigingsoplossingen voor jouw zonnepanelen en vertrouw op Smith Solar voor hoogwaardige producten en
                    professionele service. Benieuwd naar de kosten en mogelijkheden? Neem vandaag nog contact met ons op voor een offerte.
                </p>
                <Link to="/offerte/" className="btn btn-primary">
                    Offerte aanvragen
                </Link>
            </div>

            <br />

            <ProductenBevestigingsmateriaalCarousel />

            <CallToAction />

            <div className="container text-muted mt-4">
                <p>* Opbrengstgarantie: Garantie van opbrengst vergeleken met moment van in bedrijfneming als percentage na X jaar.</p>
            </div>
        </React.Fragment>
    );
}

export default Producten;
