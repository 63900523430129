import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/solaredge-installer-plus/solaredge-installer-plus.svg";

function SolarEdge() {
    return (
        <Monitoring
            brandName="SolarEdge"
            logoUrl={ logo }
            desktopUrl="https://monitoring.solaredge.com"
            iosUrl="https://apps.apple.com/nl/app/mysolaredge/id1473952773"
            androidUrl="https://play.google.com/store/apps/details?id=com.solaredge.homeowner&hl=nl&gl=NL"
            manual="/support/solaredge/"
        />
    )
}

export default React.memo(SolarEdge);
