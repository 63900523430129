import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/solax.svg";

function SolaxPortal() {
    return (
        <Monitoring
            brandName="SolaX Portal"
            logoUrl={ logo }
            desktopUrl="https://solax-portal.com"
            iosUrl="https://apps.apple.com/nl/app/worktile/id877984901"
            androidUrl="https://solax-portal.com/downloads/android/solax.apk"
        />
    )
}

export default SolaxPortal;
