import React from "react";
import Helmet from "../components/Helmet";

function Corona() {
    return (
        <React.Fragment>
            <Helmet title="Coronamaatregelen"
                    description="Op deze pagina leggen wij uit hoe wij om gaan met het Coronavirus."/>

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Corona-maatregelen Smith Solar</h1>
                </div>
            </div>

            <div className="container">

                <p>
                    In deze roerige tijden kunnen we ons voorstellen dat er vragen zijn over onze werkwijze en
                    maatregelen in de situatie omtrent Corona. Als bedrijf willen wij in ieder geval de
                    verantwoordelijkheid nemen om onze gewaardeerde klanten én werknemers zo gezond mogelijk te houden.
                    Wij houden de berichtgeving vanuit het RIVM en de overheid nauwlettend in de gaten en nemen daarom
                    voorzorgsmaatregelen:
                </p>

                <h2>Gaat mijn afspraak met de adviseur door?</h2>

                <p>
                    Heeft u een afspraak met een adviseur? Dan gaat deze in principe gewoon door. Onze adviseurs houden
                    zich aan de voorzorgsmaatregelen die vanuit de overheid en het RIVM zijn opgesteld:
                </p>

                <ul>
                    <li>Zij zullen u geen vriendelijke handdruk geven bij binnenkomst en zullen onnodig fysiek contact vermijden.</li>
                    <li>Zij zullen voldoende afstand bewaren.</li>
                </ul>

                <p>
                    Uiteraard zal de adviseur bij enige vorm van griep of verkoudheid zijn verantwoordelijkheid nemen en
                    vanuit onze kant de afspraak annuleren.
                </p>

                <p>
                    Heeft u zelf de griep of verkoudheidsverschijnselen of twijfelt u om een andere reden of de afspraak
                    door kan gaan? Neem dan contact met ons op via telefoonnummer:{" "}
                    <a href="tel:+31174235120">0174 235 120</a>, e-mail:{" "}
                    <a href="mailto:solar@smithtechniek.nl">solar@smithtechniek.nl</a>.
                </p>

                <h2>Gaat de montage van de zonnepaneleninstallatie door?</h2>

                <p>
                    U heeft waarschijnlijk maandenlang uitgekeken naar de dag dat de zonnepaneleninstallatie gemonteerd
                    wordt. Onze monteurs komen daarom met alle plezier op de geplande datum de installatie bij u
                    plaatsen. Uiteraard houden ook zij zich aan de voorzorgsmaatregelen vanuit de overheid en het RIVM
                    en houden zij zich strikt aan de hygiëneregels:
                </p>

                <ul>
                    <li>Zij zullen u geen vriendelijke handdruk geven bij binnenkomst en zullen onnodig fysiek contact vermijden.</li>
                    <li>Zij zullen voldoende afstand bewaren.</li>
                    <li>Pauzeren doen zij in de montagebus i.p.v. bij jou aan de keukentafel.</li>
                    <li>Zij zullen hun handen desinfecteren met desinfectiemiddel alvorens zij komen monteren.</li>
                </ul>

                <p>
                    Heeft u zelf de griep of verkoudheidsverschijnselen of twijfelt u om een andere reden of de afspraak
                    door kan gaan? Neem dan contact met ons op via telefoonnummer:{" "}
                    <a href="tel:+31174235120">0174 235 120</a>, e-mail:{" "}
                    <a href="mailto:solar@smithtechniek.nl">solar@smithtechniek.nl</a>.
                </p>

                <p>
                    Zoals iedereen hopen wij op een spoedige ommekeer van de situatie zodat angst en zorgen over de
                    gezondheid niet meer nodig zijn en het snel weer ‘business as usual’ is.
                </p>

            </div>
        </React.Fragment>
    );
}

export default Corona;
