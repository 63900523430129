import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/solplanet.svg";
import manual from "./../../files/manuals/solplanet-wifi-manual.pdf";

function Solplanet() {
    return (
        <Monitoring
            brandName="Solplanet"
            logoUrl={ logo }
            desktopUrl="https://cloud.solplanet.net/login"
            iosUrl="https://apps.apple.com/nl/app/solplanet/id1607454432"
            androidUrl="https://play.google.com/store/apps/details?id=com.aiswei.international&hl=nl"
            manual={ manual }
        />
    )
}

export default React.memo(Solplanet);
