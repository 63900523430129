import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/afore.svg";
import aforeOldInvertersManual from "../../files/manuals/afore-wifi-old-inverters-manual.pdf";

function Afore() {
    return (
        <Monitoring
            brandName="Afore"
            logoUrl={ logo }
            desktopUrl="https://monitor.afore.nl"
            iosUrl="https://apps.apple.com/nl/app/afore-web-monitor/id883065196"
            androidUrl="http://af.lewei50.com/downloads/android/afore.apk"
            manual={ aforeOldInvertersManual }
        />
    )
}

export default Afore;
