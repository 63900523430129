import React from "react";
import { Link } from "react-router-dom";

import Helmet from "../../components/Helmet";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import vanDerValk from "../../img/brands/vandervalksolarsystems.svg";
import CallToAction from "../../components/CallToAction";

function ProductValkPitched() {
    return (
        <React.Fragment>
            <Helmet
                title="ValkPitched"
                description="Smith Solar gebruikt ValkPitched-montagesystemen voor het plaatsen van zonnepanelen. Sterk, duurzaam en efficiënt! Neem nu contact op voor meer info."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>ValkPitched</h1>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm mb-4 pb-4">
                <h2>ValkPitched</h2>
                <p>
                    Als je op zoek bent naar betrouwbaar bevestigingsmateriaal voor zonnepanelen op een schuine dak, dan is ValkPitched de ideale
                    keuze. Met diverse opties die geschikt zijn voor verschillende soorten daken, biedt ValkPitched hoogwaardige kwaliteit en
                    duurzaamheid voor jouw zonne-energiesysteem.
                </p>

                <h3>Betrouwbare en duurzame bevestigingsmaterialen</h3>
                <p>
                    Het montagesysteem ‘ValkPitched’ van Van der Valk is onze eerste keuze voor het bevestigen van zonnepanelen op een schuin dak.
                    ValkPitched biedt verschillende voordelen:
                </p>
                <div className="row">
                    <div className="col-xl-4 col-md-6 my-3">
                        <h3 style={{ fontSize: "1.3rem" }}>Duurzaam en betrouwbaar</h3>
                        <p>Het ValkPitched-systeem voldoet aan de hoogste normen op het gebied van betrouwbaarheid en duurzaamheid.</p>
                    </div>
                    <div className="col-xl-4 col-md-6 my-3">
                        <h3 style={{ fontSize: "1.3rem" }}>Hoogwaardige materialen</h3>
                        <p>De materialen zijn van de beste kwaliteit en bestand tegen alle weersomstandigheden.</p>
                    </div>
                    <div className="col-xl-4 col-md-6 my-3">
                        <h3 style={{ fontSize: "1.3rem" }}>Garandeert prestaties van zonne-energiesysteem</h3>
                        <p>ValkPitched garandeert een langdurige prestatie van je zonne-energiesysteem.</p>
                    </div>
                </div>

                <h3 className="mt-4">ValkPitched - Clamp</h3>
                <p>
                    De ValkPitched - Clamp is een innovatief klemsysteem dat een zeer sterke en veilige bevestiging van zonnepanelen op particuliere
                    pannendaken biedt.
                </p>
                <div className="mb-4">
                    <p>Unieke eigenschappen van dit montagesysteem:</p>
                    <ul>
                        <li>klemsysteem met universele tussen- en eindklemmen (H 28-50 mm)</li>
                        <li>minder dakbevestiging noodzakelijk door zeer sterk profiel</li>
                        <li>koppelstuk van gelijke sterkte als profiel, dus geen zwakke plekken</li>
                        <li>praktische en veilige zijmontage</li>
                    </ul>
                </div>
                <h4>Zonnepanelen op schuurdaken</h4>
                <p>
                    Bij Smith Solar hebben we veel ervaring met het plaatsen van zonnepanelen op schuurdaken door middel van de ValkPitched -
                    Trapezoidal. Bekijk onze <Link to="/zonnepanelen/tuinders/">tuinderspagina</Link> voor meer informatie en ontdek hoe wij jouw
                    tuinderij kunnen voorzien van dit efficiënte montagesysteem.
                </p>
            </div>
            <div className="container my-5">
                <div className="row align-items-center">
                    <div className="col-md-7">
                        <h3>Zonnepanelen installeren</h3>
                        <p>
                            Voor het installeren van zonnepanelen kun je beroep doen op de ervaren installateurs van Smith Solar. Wij zorgen voor een
                            snelle en vakkundige montage van de zonnepanelen. Het is ook mogelijk om het systeem zelf te monteren, waardoor je
                            bespaart op de installatiekosten. Met een montagesysteem van ValkPitched ben je verzekerd van een stevige en duurzame
                            bevestiging van je zonnepanelen.
                        </p>
                        <Link to="/zonnepanelen/installeren/">Lees hier meer over het installeren van zonnepanelen</Link>
                    </div>
                    <div className="col-md-5">
                        <div className="card-rounded">
                            <img src={vanDerValk} className="img-fluid" alt="Logo van der Valk" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container container-sm card-rounded mb-5">
                <h2>Contacteer ons voor meer informatie</h2>
                <p>
                    Wil je meer weten over ValkPitched en hoe deze stevige montagesystemen jouw zonne-energieproject kunnen ondersteunen? Neem vandaag
                    nog <Link to="/contact/">contact</Link> met ons op en ontvang advies op maat. De experts van Smith Solar B.V. beantwoorden graag
                    al je vragen en helpen je bij het realiseren van jouw duurzame energiedoelen.
                </p>
            </div>

            <CallToAction />
        </React.Fragment>
    );
}

export default React.memo(ProductValkPitched);
