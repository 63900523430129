import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/solis.svg";

function SolisCloud() {
    return (
        <Monitoring
            brandName="Solis Cloud"
            logoUrl={ logo }
            desktopUrl="https://www.soliscloud.com/"
            iosUrl="https://apps.apple.com/gb/app/soliscloud/id1371324452"
            androidUrl="https://play.google.com/store/apps/details?id=com.ginlongsolis"
        />
    )
}

export default SolisCloud;
