import React from "react";
import CallToAction from "./CallToAction";

function BTWCallToAction() {
    return (
        <CallToAction
            title="Vraag vandaag nog de BTW van uw zonnepanelen terug!"
            subtitle="Deze service leveren wij alleen aan klanten van Smith Solar B.V."
            buttonText="Klik hier"
            buttonLink="https://btwterugvraag.smithsolar.nl/terugvraag"
        />
    )
}

export default BTWCallToAction;
