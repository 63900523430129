import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from "react-spinner-material";
import BSNFormatter from "./components/BSNFormatter";
import IBANFormatter from "./components/IBANFormatter";

function BTWTerugvraagForm({ fields, onChange, onSubmit, onRecaptchaVerify, recaptchaRef, loading }) {
    return (
        <form onSubmit={ onSubmit }>
            <div className="card mb-3">
                <div className="card-body">
                    <h3>Persoonlijke informatie</h3>
                    <div className="form-group">
                        <label htmlFor="initials">Initialen</label>
                        <input
                            type="text" className="form-control" id="initials" required disabled={ loading }
                            value={ fields.initials }
                            onChange={ (event) => onChange({ initials: event.target.value.toUpperCase() }) }
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="lastname">Achternaam</label>
                        <input
                            type="text" className="form-control" id="lastname" required disabled={ loading }
                            value={ fields.lastName }
                            onChange={ (event) => onChange({ lastName: event.target.value }) }
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="birthday">Geboortedatum</label>
                        <input
                            type="date" className="form-control" id="birthday" required disabled={ loading }
                            value={ fields.birthday }
                            onChange={ (event) => onChange({ birthday: event.target.value }) }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h3>Contact</h3>
                    <div className="form-group">
                        <label htmlFor="phonenumber">Telefoonnummer</label>
                        <input
                            type="text" className="form-control" id="phonenumber" required disabled={ loading }
                            value={ fields.phoneNumber }
                            onChange={ (event) => onChange({ phoneNumber: event.target.value }) }
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email" className="form-control" id="email" required disabled={ loading }
                            value={ fields.email }
                            onChange={ (event) => onChange({ email: event.target.value }) }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h3>Adres</h3>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label htmlFor="street">Straat</label>
                                <input
                                    type="text" className="form-control" id="street" required disabled={ loading }
                                    value={ fields.street }
                                    onChange={ (event) => onChange({ street: event.target.value }) }
                                />
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="housenumber">Huisnummer + Toevoeging</label>
                                <input
                                    type="text" className="form-control" id="housenumber" required disabled={ loading }
                                    value={ fields.houseNumber }
                                    onChange={ (event) => onChange({ houseNumber: event.target.value }) }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="postalcode">Postcode</label>
                        <input
                            type="text" className="form-control" id="postalcode" required disabled={ loading }
                            value={ fields.postalCode }
                            onChange={ (event) => onChange({ postalCode: event.target.value }) }
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="city">Plaats</label>
                        <input
                            type="text" className="form-control" id="city" required disabled={ loading }
                            value={ fields.city }
                            onChange={ (event) => onChange({ city: event.target.value }) }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h3>Details</h3>
                    <div className="form-group">
                        <label htmlFor="entrepreneur">Ondernemer?</label>
                        <select
                            className="form-control" id="entrepreneur" required disabled={ loading }
                            value={ fields.entrepreneur === null ? -1 : (fields.entrepreneur ? 1 : 0) }
                            onChange={ (event) => {
                                switch(parseInt(event.target.value)) {
                                    case 1: onChange({ entrepreneur: true }); return
                                    case 0: onChange({ entrepreneur: false }); return
                                    default: onChange({ entrepreneur: null }); return
                                }
                            } }
                        >
                            <option value={ -1 }/>
                            <option value={ 0 }>Ik ben geen ondernemer</option>
                            <option value={ 1 }>Ik ben ondernemer (geweest)</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="bsn">Burgerservicenummer (BSN)</label>
                        <input
                            type="number" className="form-control" id="bsn" required disabled={ loading }
                            value={ fields.bsn }
                            onChange={ (event) => onChange({ bsn: event.target.value }) }
                        />
                        { fields.bsn.length > 0 && (
                            <div className="mt-1">
                                <BSNFormatter bsn={ fields.bsn } hideBSN/>
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="iban">IBAN-rekeningnummer</label>
                        <input
                            type="text" className="form-control" id="iban" required disabled={ loading }
                            value={ fields.iban }
                            onChange={ (event) => onChange({ iban: event.target.value }) }
                        />
                        { fields.iban.length > 0 && (
                            <div className="mt-1">
                                <IBANFormatter iban={ fields.iban } hideIBAN/>
                            </div>
                        )}
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="ibanName">Naam van IBAN rekening</label>
                        <input
                            type="text" className="form-control" id="ibanName" required disabled={ loading }
                            value={ fields.ibanName }
                            onChange={ (event) => onChange({ ibanName: event.target.value }) }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h3>Uw installatie</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-0">
                                <label htmlFor="wp">Totaal aantal Wp van uw installatie</label>
                                <input
                                    type="number" className="form-control" id="wp" required disabled={ loading }
                                    value={ fields.wp }
                                    onChange={ (event) => onChange({ wp: event.target.value }) }
                                />
                                <small className="form-text text-muted">
                                    Deze informatie kan meestal op pagina 2 van uw offerte/bevestiging gevonden
                                    worden. Zorg wel dat u het aantal <b>Wp</b> invult en niet het aantal kWh.
                                    Gelieve niet afronden.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h3>Identiteitsbevestiging</h3>

                    <p className="text-muted">Toegestaande bestandstypes: JPG, JPEG, PNG en PDF.</p>

                    <div className="form-group">
                        <label htmlFor="identification1">
                            Kopie/duidelijke foto van <b>voorkant (en achterkant)</b> van uw paspoort/rijbewijs/ID-kaart
                        </label>
                        <input
                            type="file" className="form-control-file" id="identification1" required disabled={ loading }
                            onChange={ (event) => onChange({ fileIdentification1: event.target.files[0] }) }
                        />
                    </div>

                    <hr className="my-4"/>

                    <div className="form-group">
                        <label htmlFor="identification2">
                            Kopie/duidelijke foto van <b>achterkant</b> van uw paspoort/rijbewijs/ID-kaart
                        </label>
                        <input
                            type="file" className="form-control-file" id="identification2" disabled={ loading }
                            onChange={ (event) => onChange({ fileIdentification2: event.target.files[0] }) }
                        />
                        <span className="form-text text-muted">
                                    Indien u apparte bestanden voor uw paspoort/rijbewijs/ID-kaart kopieën heeft, kunt u dit veld daarvoor gebruiken.
                                    <br/>
                                    <b>Let op! Achterkant van het identiteitskaartje in nieuwere paspoorten is ook benodigd!</b>
                                </span>
                    </div>

                    <hr className="my-4"/>

                    <div className="form-group mb-0">
                        <label htmlFor="bankCard">
                            Kopie/duidelijke foto van (de voorkant van) uw bankpas / bankafschrift
                        </label>
                        <input
                            type="file" className="form-control-file" id="bankCard" required disabled={ loading }
                            onChange={ (event) => onChange({ fileBankCard: event.target.files[0] }) }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="custom-control custom-checkbox mb-2">
                        <input
                            type="checkbox" className="custom-control-input" id="iamcustomer" required disabled={ loading }
                            checked={ fields.acceptCustomer }
                            onChange={ (event) => onChange({ acceptCustomer: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="iamcustomer">
                            Hierbij zeg ik dat ik klant van <span className="b-solar">Smith Solar B.V.</span> ben.
                        </label>
                    </div>

                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox" className="custom-control-input" id="iaccept" required disabled={ loading }
                            checked={ fields.acceptPolicies }
                            onChange={ (event) => onChange({ acceptPolicies: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="iaccept">
                            Ik ga akkoord met de{" "}
                            <a href="/btwterugvraag/algemene-voorwaarden/" target="_blank" rel="noopener noreferrer">
                                algemene voorwaarden{" "}
                                <small>
                                    <i className="fas fa-external-link"/>
                                </small>
                            </a>
                            {" "}èn de{" "}
                            <a href="/btwterugvraag/privacyverklaring/" target="_blank" rel="noopener noreferrer">
                                privacyverklaring{" "}
                                <small>
                                    <i className="fas fa-external-link"/>
                                </small>
                            </a>.
                        </label>
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-end mb-5">
                { loading ? (
                    <Spinner radius={40} color="#414C36" stroke={5} visible={true} />
                ) : (
                    <input type="submit" className="btn btn-primary" value="Versturen"/>
                )}
            </div>

            <ReCAPTCHA
                ref={ recaptchaRef }
                size="invisible"
                sitekey="6LcavTIUAAAAADLEdpsrKb9D0O5oKww7p1ZcFKEg"
                onChange={ onRecaptchaVerify }
            />
        </form>
    )
}

export default BTWTerugvraagForm;
