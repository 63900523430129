import React from "react";
import {
    Link
} from "react-router-dom";

function HomeCard({ icon, title, description, link, linkText = "Meer informatie", half }) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className={ (half ? "col-lg-3 col-xl-2" : "col-lg-1half col-xl-1") + " text-center" }>
                        <i className={ icon + " fa-fw text-primary" } style={{ fontSize: "60px" }}/>
                        <br/><br/>
                    </div>
                    <div className={ half ? "col-lg-9 col-xl-10" : "col-lg-10half col-xl-11" }>
                        <h4 className="card-title">
                            { title }
                        </h4>
                        <p className="card-text">
                            { description }
                        </p>
                        { link && (
                            link.startsWith("https://") ? (
                                <a href={ link } className="btn btn-primary">
                                    { linkText }
                                </a>
                            ) : (
                                <Link to={ link } className="btn btn-primary">
                                    { linkText }
                                </Link>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(HomeCard);
