import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import Hotjar from "@hotjar/browser";
import ReactPixel from "react-facebook-pixel";

const useTracking = (
    trackingIds
) => {
    const { listen } = useHistory();

    useEffect(() => {
        return listen((location) => {
            if (!trackingIds) {
                console.log("Tracking not enabled, as `trackingIds` was not given.");
                return;
            }

            // Update Google Tag Manager / Google Analytics
            ReactGA.send({ hitType: "pageview", page: location.pathname });
            ReactGA.ga('tpm.send', 'event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-8979034/pagev0/homep0+standard'
            });

            // Update Hotjar
            Hotjar.stateChange(location.pathname);

            // Update Facebook Pixel
            ReactPixel.pageView();
        })
    }, [trackingIds, listen]);
}

export default useTracking;
