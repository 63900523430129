import React from "react";
import Helmet from "../../components/Helmet";

import { Link } from "react-router-dom";

import CallToAction from "../../components/CallToAction";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";

import { StepsList, Step } from "../../components/StepsList";

function ZonnepanelenInstalleren() {
    return (
        <React.Fragment>
            <Helmet
                title="Zonnepanelen installatie"
                description="Stap moeiteloos over op duurzame energie met een zonnepanelen installatie door ervaren monteurs van Smith Solar. Vraag direct een offerte aan!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Zonnepanelen</h1>
                    <p>Installeren van zonnepanelen</p>
                    <JumbotronCallToAction />
                </div>
            </div>

            <div className="container container-sm">
                <div className="article-open">
                    <p>
                        Bij Smith Solar geloven we dat een helder en gestructureerd proces essentieel is voor een succesvolle zonnepanelen
                        installatie. Van het eerste contact tot de nazorg, wij begeleiden je bij elke stap. Ontdek hieronder hoe wij te werk gaan om
                        jouw huis te transformeren in een bron van duurzame energie.
                    </p>
                </div>

                <div className="my-5">
                    <h2 className="mb-4">Zonnepanelen installeren in 5 stappen</h2>
                    <StepsList>
                        <Step stepTitle={1} title="Offerte">
                            <p>
                                Vraag een <Link to="/offerte/">offerte</Link> aan.
                            </p>
                        </Step>
                        <Step stepTitle={2} title="Adviesgesprek">
                            <p>Plan een adviesgesprek in voor persoonlijk advies.</p>
                        </Step>
                        <Step stepTitle={3} title="Offerte opstellen">
                            <p>Wij stellen een eerste offerte op.</p>
                        </Step>
                        <Step stepTitle={4} title="Afspraak maken">
                            <p>We maken een afspraak op locatie om eventuele knelpunten te voorkomen.</p>
                        </Step>
                        <Step stepTitle={5} title="Installeren">
                            <p>Onze ervaren monteurs komen de zonnepanelen installeren.</p>
                        </Step>
                    </StepsList>
                </div>

                <h3>De 5 stappen uitgelegd</h3>
                <p> Maak kennis met ons zorgvuldig ontworpen proces.</p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Offerte aanvragen
                </h3>
                <p>
                    Jouw duurzame reis begint met een simpele stap: het aanvragen van een offerte. Dit doe je gemakkelijk via onze website.
                    <Link to="/offerte/"> Vul het offerteformulier in</Link> en wij nemen snel contact met je op om je wensen en de mogelijkheden te
                    bespreken.
                </p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Adviesgesprek voor persoonlijk advies
                </h3>
                <p>
                    Na je offerteaanvraag plannen we een adviesgesprek. Tijdens dit gesprek duiken we dieper in jouw specifieke situatie en wensen. We
                    voorzien je van persoonlijk advies en beantwoorden al je vragen. Zo zorgen we ervoor dat het zonnepanelensysteem perfect bij jou
                    past.
                </p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Eerste offerte wordt opgesteld
                </h3>
                <p>
                    Op basis van het adviesgesprek stellen we een eerste offerte voor je op. Deze offerte geeft een duidelijk beeld van de kosten, de
                    verwachte opbrengst van de zonnepanelen en de terugverdientijd. We zorgen voor transparantie en helderheid, zodat je precies weet
                    waar je aan toe bent.
                </p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Afspraak op locatie
                </h3>
                <p>
                    Voordat we de installatie in gang zetten, maken we een afspraak om jouw huis of bedrijf te bezoeken. Tijdens deze afspraak
                    controleren we het dak en de omgeving op eventuele knelpunten. Zo garanderen we een soepele installatie zonder verrassingen.
                </p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Installatiedag
                </h3>
                <p>
                    De grote dag is aangebroken: onze eigen ervaren monteurs komen langs om de zonnepanelen op jouw dak te monteren. Dankzij hun
                    expertise verloopt de installatie snel en efficiënt. Voor je het weet, geniet je van je eigen duurzaam opgewekte energie.
                </p>

                <h2 className="mt-5">Nazorg: Jouw huis is nu duurzamer</h2>
                <p>
                    Gefeliciteerd, jouw huis is nu een stap duurzamer! Maar onze service stopt hier niet. Onze klantenservice biedt je persoonlijke
                    aandacht voor eventuele vragen of behoeften achteraf. Of het nu gaat om onderhoud, monitoring of een andere vraag,{" "}
                    <Link to="/contact/">neem contact met ons op</Link> en wij staan voor je klaar.
                </p>

                <h3 className="text-muted font-weight-normal" style={{ fontSize: "1.5rem" }}>
                    Zet vandaag nog de stap naar zonne-energie
                </h3>
                <p>
                    Dit zorgvuldige proces garandeert een zorgeloze overgang naar zonne-energie. Ben je klaar om de stap te zetten?{" "}
                    <Link to="/offerte/">Vraag nu je offerte aan</Link> bij Smith Solar en start jouw duurzame toekomst vandaag nog. Samen maken we de
                    wereld groener, één zonnepaneel tegelijk.
                </p>
            </div>

            <CallToAction />
        </React.Fragment>
    );
}

export default ZonnepanelenInstalleren;
