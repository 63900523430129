import React from "react";
import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/goodwe.svg";
import manual from "./../../files/manuals/goodwe-wifi-manual.pdf";

function GoodWe() {
    return (
        <Monitoring
            brandName="GoodWe"
            logoUrl={ logo }
            desktopUrl="https://www.semsportal.com/home/login"
            iosUrl="https://apps.apple.com/nl/app/sems-portal/id1406230958"
            androidUrl="https://play.google.com/store/apps/details?id=com.goodwe.semsportal&hl=nl"
            manual={ manual }
        />
    )
}

export default GoodWe;
