import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import JumbotronCallToAction from "../../components/JumbotronCallToAction";
import CallToAction from "../../components/CallToAction";

import hdWave from "../../img/producten/solaredge/solaredge-hd-wave-no-display.png";
import threePhase from "../../img/producten/solaredge/solaredge-3-phase-no-display.png";
import optimizer from "../../img/producten/solaredge/solaredge-optimizer.png";
import battery from "../../img/producten/solaredge/solaredge-home-battery-48V.jpg";

function ProductSolarEdge() {
    return (
        <React.Fragment>
            <Helmet
                title="SolarEdge"
                description="Haal het maximale rendement uit jouw zonnepanelen met SolarEdge. Vraag nu je gratis offerte aan voor een efficiënte en betrouwbare oplossing!"
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>SolarEdge</h1>
                    <JumbotronCallToAction/>
                </div>
            </div>

            <div className="container container-sm">

                <div className="article-open">
                    <p>
                        Wil je het maximale rendement uit je zonnepanelen halen en tegelijkertijd schaduwproblemen
                        minimaliseren? Dan is SolarEdge dé oplossing voor jouw zonnepaneleninstallatie. Als marktleider op
                        het gebied van zonne-energie-oplossingen, biedt SolarEdge een unieke technologie met optimizers die
                        de efficiëntie van je systeem aanzienlijk verbeteren. Bij Smith Solar zijn we trots om SolarEdge op
                        te nemen in ons assortiment en deze geavanceerde oplossing aan te bieden aan onze klanten.
                    </p>
                </div>

                <div className="card-rounded py-3 mb-3">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <div className="d-flex justify-content-center mr-3">
                            <img
                                src={ hdWave }
                                alt="SolarEdge HD-Wave 1 fase omvormer"
                                className="img-fluid h-100"
                                style={{ maxHeight: "150px" }}
                            />
                        </div>
                        <div className="d-flex justify-content-center ml-3">
                            <img
                                src={ threePhase }
                                alt="SolarEdge 3 fase omvormer"
                                className="img-fluid h-100"
                                style={{ maxHeight: "200px" }}
                            />
                        </div>
                    </div>
                </div>

                <h2>
                    Wat is SolarEdge?
                </h2>

                <p>
                    SolarEdge is een innovatief systeem dat de traditionele werking van zonnepanelen verbetert. Dankzij
                    de <b>geïntegreerde SolarEdge omvormer en optimizer</b> wordt schaduwwerking per paneel tegengegaan.
                    Hierdoor kunnen schaduwen op één paneel de prestaties van de andere panelen niet negatief
                    beïnvloeden. Zelfs kleine vervuilingen of vogelpoep kunnen een aanzienlijk verlies van opbrengst
                    veroorzaken bij een conventioneel systeem, terwijl SolarEdge ervoor zorgt dat dit niet langer een
                    probleem is.
                </p>

            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <h2>
                            Verhoog je rendement met een SolarEdge Optimizer
                        </h2>

                        <p>
                            Met een <b>SolarEdge Optimizer</b> profiteer je van een hoger rendement dan ooit tevoren. Bij traditionele
                            zonnepanelen installaties kunnen kleine verschillen tussen de panelen leiden tot een lagere totale
                            opbrengst. SolarEdge pakt dit probleem aan door elk paneel afzonderlijk te optimaliseren, waardoor
                            alle panelen de prestaties van het best presterende paneel volgen. Zo benut je het volledige
                            potentieel van elk zonnepaneel en maximaliseer je je energieopbrengst.
                        </p>
                    </div>
                    <div className="col">
                        <div className="card-rounded mb-3">
                            <img src={ optimizer } className="img-fluid" alt="SolarEdge Optimizer"/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container container-sm">

                <h2>
                    Meer voordelen van SolarEdge
                </h2>

                <p>
                    Met SolarEdge en Smith Solar ben je verzekerd van een efficiënte, betrouwbare en toekomstgerichte
                    zonne-energie-oplossing. Ontdek de voordelen van SolarEdge en maak vandaag nog de overstap naar
                    duurzame energie.
                </p>

                <h3>
                    Geavanceerd monitoringssysteem
                </h3>

                <p>
                    Nieuwsgierig naar de prestaties van je zonnepanelen? Met SolarEdge kun je elk paneel afzonderlijk
                    monitoren. Ons uitgebreide monitoringplatform, te raadplegen via de handige <b>mySolarEdge</b> app, geeft
                    je inzicht in de opbrengst van elk individueel paneel of groepen van twee panelen. Zo kun je
                    nauwkeurig zien welke panelen optimaal functioneren en eventuele problemen snel opsporen. Met deze
                    waardevolle informatie blijf je de baas over je energieproductie!
                </p>

                <h3>
                    Geschikt voor diverse installaties
                </h3>

                <p>
                    Of je nu een kleine residentiële installatie hebt met slechts 6 panelen of een uitgebreide
                    commerciële installatie met duizenden panelen, SolarEdge is er klaar voor. Het systeem schaalt
                    moeiteloos mee met jouw behoeften en zorgt voor een <b>betrouwbare energieopwekking, ongeacht de omvang
                    van je zonne-energieproject</b>. Bij Smith Solar adviseren we je graag over de beste
                    SolarEdge-configuratie voor jouw situatie.
                </p>

            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">

                        <h3>
                            Ondersteuning voor de thuisbatterij
                        </h3>

                        <p>
                            Bij Smith Solar begrijpen we dat steeds meer mensen geïnteresseerd zijn in het opslaan van
                            overtollige energie met behulp van de thuisbatterij. SolarEdge biedt <b>naadloze integratie met de
                            thuisbatterij</b>. Door de DC-gekoppelde aansluiting worden omzetting verliezen geminimaliseerd, wat
                            resulteert in een efficiëntere opslag van de geproduceerde energie.
                        </p>
                    </div>
                    <div className="col">
                        <div className="card-rounded mb-3">
                            <img src={ battery } className="img-fluid" alt="SolarEdge Optimizer"/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container container-sm">

                <h3>
                    Lange garantieperiode
                </h3>

                <p>
                    Bij het investeren in zonne-energie is het belangrijk om te weten dat je kunt vertrouwen op de
                    kwaliteit van de gebruikte producten. Met SolarEdge ben je gedekt door een uitgebreide <b>garantie van
                    12 jaar op de omvormer en zelfs 25 jaar op de optimizer</b>. Zo geniet je van gemoedsrust en een
                    duurzaam systeem voor de lange termijn.
                </p>

                <h2>
                    Contact
                </h2>

                <p>
                    Heb je nog vragen over SolarEdge of wil je meer weten over onze zonne-energieoplossingen? Aarzel
                    niet om <Link to="/contact/">contact</Link> op te nemen met ons team van experts. We staan klaar om al jouw vragen te
                    beantwoorden en jou te helpen bij het kiezen van de beste zonne-energie-oplossing voor jouw
                    specifieke behoeften.
                </p>

                <h2>
                    Offerte aanvragen voor SolarEdge
                </h2>

                <p>
                    Ben je klaar om de voordelen van SolarEdge te ervaren? Smith Solar staat voor je klaar om je te
                    helpen bij het realiseren van jouw zonne-energie dromen. Vraag vandaag nog een
                    offerte aan voor jouw SolarEdge-systeem. Ons team van deskundige installateurs staat klaar om jou te
                    begeleiden bij elke stap van het proces, van advies tot installatie.
                </p>

                <CallToAction
                    title="Waar wacht je nog op?"
                    subtitle="Maak gebruik van de nieuwste technologie en verhoog je zonne-energieproductie met SolarEdge!"
                />

            </div>
        </React.Fragment>
    )
}

export default React.memo(ProductSolarEdge);
