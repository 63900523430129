import React from "react";

function VerticalCard({ children, title, image }) {
    return (
        <div className="card-rounded h-100">
            { image && (
                <div>
                    <img
                        src={image}
                        alt={title}
                        className="img-fluid"
                        style={{maxHeight: "250px"}}
                    />
                </div>
            )}
            <h3>
                {title}
            </h3>
            {children}
        </div>
    );
}

export default React.memo(VerticalCard);
