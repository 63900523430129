import React from "react";

import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/taylor.svg";

function TaylorMonitoring() {
    return (
        <Monitoring
            brandName="Taylor"
            logoUrl={ logo }
            desktopUrl="https://dashboard.taylor.solar/"
            iosUrl="https://apps.apple.com/us/app/taylor-dashboard/id6449489836"
            androidUrl="https://play.google.com/store/apps/details?id=solar.taylor.dashboard"
        />
    )
}

export default React.memo(TaylorMonitoring);
