import React from "react";
import {
    Link
} from "react-router-dom";

import Helmet from "../../components/Helmet";
import SupportQuestionAnswer from "./components/SupportQuestionAnswer";

function SupportOutage() {
    return (
        <React.Fragment>
            <Helmet
                title="Storing - Support"
                description="Heeft u een storing met uw installatie? We helpen u graag om het probleem op te lossen. Op deze pagina vind u informatie en hoe we dit kunnen oplossen."
            />

            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Support</h1>
                    <p>Storing</p>
                </div>
            </div>

            <div className="container text-center my-5">
                <Link to="/support/" className="btn btn-secondary">
                    <i className="fa-solid fa-chevron-left mr-2"/>
                    Terug naar support
                </Link>
            </div>

            <div className="container container-sm">
                <h2>Veelgestelde vragen</h2>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Mijn omvormer doet helemaal niets meer, wat nu?
                    </React.Fragment>
                }>
                    <p>
                        Dit kan verschillende oorzaken hebben. Hier kunt u zelf alvast een aantal dingen controleren:
                    </p>
                    <ul>
                        <li>
                            Controleer of de schakelaar op de omvormer op aan staat.
                        </li>
                        <li>
                            Controleer of de groep waar de zonnepanelen op aangesloten zit in uw meterkast aan staat.
                        </li>
                    </ul>
                    <p>
                        Staat zowel de groep van de zonnepanelen in de meterkast aan als de schakelaar van de
                        omvormer aan? Neemt u dan contact op met{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>{ " " }
                        en vermeld het volgende in de mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Serienummer van de omvormer (u kunt deze vaak aan de zijkant van uw omvormer vinden)</li>
                    </ul>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        1 of meerdere panelen wekken minder of niets op, wat nu?
                    </React.Fragment>
                }>
                    <p>
                        Dat 1 of meerdere van uw panelen minder opbrengst hebben of helemaal niets opwekken kan meerdere
                        oorzaken hebben. Vaak ligt het eraan dat de monitoring portal de gegevens vertraagd binnen
                        krijgt van uw omvormer.
                    </p>
                    <p>
                        Als dit langer dan een week speelt, neem dan contact op met{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>{ " " }
                        en vermeld het volgende in de mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Serienummer van de omvormer (u kunt deze vaak aan de zijkant van uw omvormer vinden)</li>
                        <li>Welk paneel volgens u minder opwekt</li>
                    </ul>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Er valt steeds een schakelaar in de meterkast uit, wat nu?
                    </React.Fragment>
                }>
                    <p>
                        Als het de groep van de zonnepaneleninstallatie blijkt te zijn, maakt u een foto van de
                        meterkast en mailt u de foto naar{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a>{ " " }
                        en vermeld het volgende in de mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Welke momenten an de dag de schakelaar uitslaat</li>
                    </ul>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Mijn omvormer maakt op bepaalde momenten van de dag geluid
                    </React.Fragment>
                }>
                    <p>
                        Het is normaal dat de omvormer begin van de ochtend bij het opstarten van de omvormer en
                        einde van de middag bij bij zonsondergang een tikkend geluid maakt.
                    </p>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Er zit kleurverschil tussen mijn zonnepanelen
                    </React.Fragment>
                }>
                    <p>
                        Soms komt het voor dat er kleurverschil zit tussen de zonnepanelen. Dit kan komen door
                        verschillende partijen van de fabrikant. Dit heeft geen invloed op de opbrengst van de
                        zonnepanelen. Mocht u hier toch vragen over hebben, neem dan contact op met{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> en vermeld het volgende in de
                        mail:
                    </p>
                    <ul>
                        <li>Uw volledige naam</li>
                        <li>Adresgegevens</li>
                        <li>Welke panelen volgens u een kleurverschil hebben</li>
                    </ul>
                </SupportQuestionAnswer>

                <SupportQuestionAnswer question={
                    <React.Fragment>
                        Mijn omvormer wordt zeer warm
                    </React.Fragment>
                }>
                    <p>
                        Het is normaal dat een omvormer warm wordt zodra er veel opwekking is van de zonnepanelen.
                        De omvormer is gelukkig beveiligd waardoor deze zichzelf uitschakelt zodra de
                        temperatuur te hoog oploopt.
                    </p>
                </SupportQuestionAnswer>

                <div className="text-center mt-5">
                    <h2>Staat uw vraag er niet tussen?</h2>
                    <p style={ { fontSize: "1.2rem" } }>
                        Neem dan contact op met onze support afdeling. Wij helpen u graag verder!
                        Stuur een mail naar{ " " }
                        <a href="mailto:support@smithsolar.nl">support@smithsolar.nl</a> of bel naar{ " " }
                        <a href="tel:+31174235120">0174 235 120</a>.
                    </p>
                </div>

            </div>
        </React.Fragment>
    );
}

export default React.memo(SupportOutage);
