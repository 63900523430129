import React from "react";

function FormSelectionCard({ option, onClick, selected }) {
    return (
        <div
            className="col-xl col-lg-4 col-md-6 pointer-cursor mb-2"
            onClick={ () => onClick(option) }
        >
            <div className={ "card h-100" + (selected ? " active" : "") }>
                <div className="card-body py-3">
                    <div className="d-flex flex-row flex-sm-column align-items-center align-items-sm-start">
                        <div style={{ fontSize: "1.5rem" }} className="d-flex flex-column flex-sm-row mr-3 mr-sm-0">
                            { option.icons.map((icon, index) => (
                                <div key={ index }>
                                    <i className={ icon + " fa-fw mr-sm-2" }/>
                                </div>
                            ))}
                        </div>
                        <div>
                            { option.name }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(FormSelectionCard);
