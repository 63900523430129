import React from "react";

import Monitoring from "../../components/pages/monitoring/Monitoring";

import logo from "./../../img/brands/hoymiles.png";

function Hoymiles() {
    return (
        <Monitoring
            brandName="Hoymiles"
            logoUrl={ logo }
            desktopUrl="https://global.hoymiles.com/platform/login"
            iosUrl="https://apps.apple.com/nl/app/s-miles-enduser/id1544760866"
            androidUrl="https://play.google.com/store/apps/details?id=com.hm.hemaiClient1&hl=nl"
        />
    )
}

export default React.memo(Hoymiles);
